import { gql } from '@apollo/client'

//LOAS
export const UPDATE_LOA_BY_ID = gql`
  mutation LOAUpdateOne($loa: UpdateOneLOAInput!, $id: MongoID) {
    LOAUpdateOne(record: $loa, filter: { _id: $id }) {
      record {
        _id
      }
    }
  }
`

export const CREATE_LOA = gql`
  mutation ($loa: CreateOneLOAInput!) {
    LOACreateOne(record: $loa) {
      record {
        _id
        name
        code
      }
    }
  }
`

export const DELETE_LOA_BY_ID = gql`
  mutation ($id: MongoID!) {
    LOARemoveById(_id: $id) {
      record {
        _id
      }
    }
  }
`

//Certifications
export const UPDATE_CERTIFICATION_BY_ID = gql`
  mutation TrainingCertificationUpdateOne(
    $certification: UpdateOneTrainingCertificationInput!
    $id: MongoID
  ) {
    TrainingCertificationUpdateOne(
      record: $certification
      filter: { _id: $id }
    ) {
      record {
        _id
      }
    }
  }
`

export const CREATE_CERTIFICATION = gql`
  mutation ($certification: CreateOneTrainingCertificationInput!) {
    TrainingCertificationCreateOne(record: $certification) {
      record {
        _id
        name
      }
    }
  }
`

export const DELETE_CERTIFICATION_BY_ID = gql`
  mutation ($id: MongoID!) {
    TrainingCertificationRemoveById(_id: $id) {
      record {
        _id
      }
    }
  }
`

//Licenses
export const UPDATE_LICENSE_BY_ID = gql`
  mutation LOAUpdateOne($license: UpdateOneStateLicenseInput!, $id: MongoID) {
    StateLicenseUpdateOne(record: $license, filter: { _id: $id }) {
      record {
        _id
      }
    }
  }
`

export const CREATE_LICENSE = gql`
  mutation ($license: CreateOneStateLicenseInput!) {
    StateLicenseCreateOne(record: $license) {
      record {
        _id
        name
        code
        states
      }
    }
  }
`

export const DELETE_LICENSE_BY_ID = gql`
  mutation ($id: MongoID!) {
    StateLicenseRemoveById(_id: $id) {
      record {
        _id
      }
    }
  }
`

// STATS
export const ADD_LOGIN_LOG = gql`
  mutation ($stats: CreateOneLogInLogInput!) {
    LogInLogCreateOne(record: $stats) {
      record {
        userId
        createdAt
      }
    }
  }
`
// Jobs
export const ADD_JOB = gql`
  mutation ($job: CreateOneJobInput!) {
    JobCreateOne(record: $job) {
      record {
        _id
        title
      }
    }
  }
`

export const UPDATE_JOB_BY_ID = gql`
  mutation ($job: UpdateByIdJobInput!, $id: MongoID!) {
    JobUpdateById(record: $job, _id: $id) {
      record {
        _id
        title
      }
    }
  }
`
export const DELETE_JOB_BY_ID = gql`
  mutation ($id: MongoID!) {
    JobRemoveById(_id: $id) {
      record {
        _id
      }
    }
  }
`

// Users
export const ADD_USER = gql`
  mutation ($user: CreateOneUserInput!) {
    UserCreateOne(record: $user) {
      record {
        _id
        email
        firstName
        lastName
        userType
        password
        firmName
        jobTypes
        profileSet
        location
        bio
        profilePicture
        status
        licenses
        certifications
      }
    }
  }
`

export const UPDATE_USER_BY_EMAIL = (email?: string) => {
  return gql`
 mutation ($user: UpdateOneUserInput!) {
   UserUpdateOne(
     record: $user
     filter: { email: "${email}" }
   ) {
     record {
       _id
     }
   }
 }
 `
}

export const UPDATE_USER_BY_ID = gql`
  mutation UserUpdateOne($user: UpdateOneUserInput!, $id: MongoID) {
    UserUpdateOne(record: $user, filter: { _id: $id }) {
      record {
        _id
      }
    }
  }
`

// Roster
export const CREATE_ROSTER = gql`
  mutation ($roster: CreateOneRosterInput!) {
    RosterCreateOne(record: $roster) {
      record {
        _id
        adjusterId
        jobId
        status
      }
    }
  }
`

export const UPDATE_ROSTER_BY_ID = gql`
  mutation RosterUpdateOne($roster: UpdateOneRosterInput!, $id: MongoID) {
    RosterUpdateOne(record: $roster, filter: { _id: $id }) {
      record {
        _id
      }
    }
  }
`

// Application
export const CREATE_APPLICATION = gql`
  mutation ($application: CreateOneApplicationInput!) {
    ApplicationCreateOne(record: $application) {
      record {
        _id
        message
        status
        adjusterId
        jobId
      }
    }
  }
`

export const UPDATE_APPLICATION_BY_ID = gql`
  mutation ApplicationUpdateOne(
    $application: UpdateOneApplicationInput!
    $id: MongoID
  ) {
    ApplicationUpdateOne(record: $application, filter: { _id: $id }) {
      record {
        _id
      }
    }
  }
`

export const DELETE_APPLICATION = gql`
  mutation ($adjusterId: MongoID!, $jobId: MongoID!) {
    ApplicationRemoveOne(adjusterId: $adjusterId, jobId: $jobId) {
      record {
        _id
      }
    }
  }
`

export const DELETE_APPLICATION_BY_ID = gql`
  mutation ($id: MongoID!) {
    ApplicationRemoveById(_id: $id) {
      record {
        _id
      }
    }
  }
`

export const DELETE_APPLICATIONS_BY_ID = gql`
  mutation ($id: MongoID!) {
    ApplicationRemoveMany(_id: $id) {
      record {
        _id
      }
    }
  }
`

// INVITATIONS
export const CREATE_INVITATION = gql`
  mutation ($invitation: CreateOneInvitationInput!) {
    InvitationCreateOne(record: $invitation) {
      record {
        _id
        message
        status
        adjusterId
        jobId
      }
    }
  }
`

export const UPDATE_INVITATION_BY_ID = gql`
  mutation InvitationUpdateOne(
    $invitation: UpdateOneInvitationInput!
    $id: MongoID
  ) {
    InvitationUpdateOne(record: $invitation, filter: { _id: $id }) {
      record {
        _id
      }
    }
  }
`

export const DELETE_INVITATION_BY_ID = gql`
  mutation ($id: MongoID!) {
    InvitationRemoveById(_id: $id) {
      record {
        _id
      }
    }
  }
`

// NOTIFICATIONS
export const CREATE_NOTIFICATION = gql`
  mutation ($notification: CreateOneNotificationInput!) {
    NotificationCreateOne(record: $notification) {
      record {
        _id
      }
    }
  }
`

export const UPDATE_NOTIFICATION_BY_ID = gql`
  mutation NotificationUpdateOne(
    $notification: UpdateOneNotificationInput!
    $id: MongoID
  ) {
    NotificationUpdateOne(record: $notification, filter: { _id: $id }) {
      record {
        _id
      }
    }
  }
`

export const DELETE_NOTIFICATION_BY_ID = gql`
  mutation ($id: MongoID!) {
    NotificationRemoveById(_id: $id) {
      record {
        _id
      }
    }
  }
`

// CHATS
export const CREATE_CHAT = gql`
  mutation ($chat: CreateOneChatInput!) {
    ChatCreateOne(record: $chat) {
      record {
        _id
        jobId
        status
        users
        deletedBy
        messages
        pinnedBy
      }
    }
  }
`

export const UPDATE_CHAT_BY_ID = gql`
  mutation ChatUpdateOne($chat: UpdateOneChatInput!, $id: MongoID) {
    ChatUpdateOne(record: $chat, filter: { _id: $id }) {
      record {
        _id
      }
    }
  }
`

export const PUSH_MESSAGE = gql`
  query PushMessage($id: MongoID, $message: JSON) {
    PushMessage(filter: { id: $id, message: $message }) {
      success
    }
  }
`

export const MARK_MESSAGE_AS_READ = gql`
  query MarkMessagesAsRead($chatId: MongoID, $messages: [JSON]) {
    MarkMessagesAsRead(filter: { chatId: $chatId, messages: $messages }) {
      success
    }
  }
`

export const PUSH_PIN_CHAT = gql`
  query PushPinChat($id: MongoID, $userId: MongoID) {
    PushPinChat(filter: { id: $id, userId: $userId }) {
      success
    }
  }
`

export const PULL_PIN_CHAT = gql`
  query PullPinChat($id: MongoID, $userId: MongoID) {
    PullPinChat(filter: { id: $id, userId: $userId }) {
      success
    }
  }
`

export const PUSH_DELETED_BY_CHAT = gql`
  query PushDeletedByChat($id: MongoID, $userId: MongoID) {
    PushDeletedByChat(filter: { id: $id, userId: $userId }) {
      success
    }
  }
`

export const PULL_DELETED_BY_CHAT = gql`
  query PullDeletedByChat($id: MongoID, $userId: MongoID) {
    PullDeletedByChat(filter: { id: $id, userId: $userId }) {
      success
    }
  }
`
