import { UserContext } from '../../contexts/UserContext'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import Map from '../../components/Map/Map'
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  AdminAdjuster,
  AdminFirm,
  DATA_SOURCE,
  EXPERIENCE_TYPES,
  EXPERIENCE_VALUES,
  Experience,
  FEMA_CATEGORY,
  LicenseCertification,
  TestimonialsConfig,
  USER_STATUS,
  User,
  UserContextType,
  UserType,
  femaCategoriesOptions,
  getFlagReason,
  userStatusOptions,
} from '../../types/userTypes'
import { ADJUSTER_ROUTES, FIRM_ROUTES, GLOBAL_ROUTES } from '../../App'
import { useLazyQuery, useMutation } from '@apollo/client'
import {
  GET_ADJUSTERS_BY_FILTERS,
  GET_ADMIN_USER_CHAT,
  GET_ALL_FIRM_JOBS,
  GET_APPLICATIONS_AND_TITLE,
  GET_FIRMS_BY_FILTERS,
  GET_INVITATIONS_AND_TITLE,
  GET_JOBS_BY_IDS,
  GET_ROSTERS,
  GET_ROSTERS_BY_FILTER,
  GET_USER_BY_ID,
} from '../../graphql/Queries'
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  GridItem,
  HStack,
  Heading,
  Image,
  Input,
  Link,
  Spinner,
  Stack,
  Tab,
  TabList,
  Table,
  TableContainer,
  Tabs,
  Tag,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tooltip,
  Tr,
  VStack,
  useDisclosure,
} from '@chakra-ui/react'
import {
  ADJUSTER_WORK_STATUS,
  Application,
  Invitation,
  JOB_REVIEW_FEEDBACK,
  JOB_REVIEW_FEEDBACK_SCORE,
  Job,
  JobStatus,
  ReviewSurvey,
  Roster,
  jobTypeOptions,
} from '../../types/jobTypes'
import {
  StateLicenseContextType,
  US_STATES,
} from '../../types/stateLicensesTypes'
import { TrainingCertificationContextType } from '../../types/trainingCertificationsTypes'
import { StateLicesesContext } from '../../contexts/StateLicesesContext'
import { TrainingCertificationsContext } from '../../contexts/TrainingCertificationsContext'
import {
  dateIsExpired,
  encodeS3URI,
  formatDate,
  getFileNameFromURL,
  getPathRoute,
  truncateNumber,
} from '../../utils/functions'
import UpdateDriverLicense from '../../components/UpdateDriverLicense/UpdateDriverLicense'
import './calendar.css'
import {
  setItemToLocalStorage,
  LOCALSTORAGE_OBJECTS_NAMES,
} from '../../utils/localStorageFunctions'
import {
  CREATE_CHAT,
  CREATE_NOTIFICATION,
  PULL_DELETED_BY_CHAT,
  PUSH_MESSAGE,
  UPDATE_USER_BY_EMAIL,
} from '../../graphql/Mutations'
import { ADJUSTANT_GREEN } from '../../themes/themes'
import { BsFlagFill, BsShieldCheck, BsShieldExclamation } from 'react-icons/bs'
import _ from 'lodash'
import FlagDocumentModal from '../../components/Modals/FlagDocumentModal'
import {
  AiFillEye,
  AiFillEyeInvisible,
  AiFillWarning,
  AiOutlineCheck,
} from 'react-icons/ai'
import { LOASContextType } from '../../types/loasTypes'
import { LOASContext } from '../../contexts/LOASContext'
import UpdateMiscellaneosDocument from '../../components/UpdateMiscellaneosDocument/UpdateMiscellaneosDocument'
import CustomDropdown from '../../components/CustomDropdown/CustomDropdown'
import JobsList from '../../components/JobsList/JobsList'
import JobsListModal from '../../components/JobsListModal/JobsListModal'
import SendMessageModal from '../../components/Modals/SendMessageModal'
import { showSuccessToast } from '../../components/Toast/Toast'
import UpdateTaxForm from '../../components/UpdateTaxForm/UpdateTaxForm'
import {
  W9_FORM_URL,
  I9_FORM_URL,
  W4_FORM_URL,
} from '../../constants/constants'
import { MdOutlinePendingActions } from 'react-icons/md'
import Label from '../../components/Label/Label'
import NIPRPDFModal from '../../components/NIPRPDF/NIPRPDFModal'
import { CHAT_STATUS, Chat } from '../../types/chatTypes'
import StarRating from '../../components/StarRating/StarRating'
import UpdateTestimonialsConfigModal from '../../components/Modals/UpdateTestimonialsConfigModal'

interface ApplicationAndJobTitle extends Application {
  jobTitle: string
}

interface InvitationAndJobTitle extends Invitation {
  jobTitle: string
}

const sortByName = (object: any[] | undefined) => {
  return object && object?.length > 0
    ? object?.sort((a, b) => a?.name?.localeCompare(b?.name))
    : []
}

const PendingApprovalTooltip = (
  <Tooltip
    label="Pending approval, this can take about 24 hours"
    fontSize="md"
    hasArrow
    placement="end-start"
  >
    <Box fontSize="xl" color="yellow.500" mr={2}>
      <MdOutlinePendingActions />
    </Box>
  </Tooltip>
)

const ApprovedTooltip = (
  <Tooltip
    label="Document Received Approved"
    fontSize="md"
    hasArrow
    placement="end-start"
  >
    <Box fontSize="xl" color="green" mr={2}>
      <AiOutlineCheck />
    </Box>
  </Tooltip>
)

const ProfileContent = ({
  viewer,
  profileUser,
  setProfileUser,
  canEditProfile,
  editorIsAdmin,
}: {
  viewer?: User
  profileUser?: User
  setProfileUser: React.Dispatch<React.SetStateAction<User | undefined>>
  canEditProfile: boolean
  editorIsAdmin: boolean
}) => {
  let [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const driversLicenseBoxRef = useRef<HTMLDivElement | null>(null)
  const w9TaxFormBoxRef = useRef<HTMLDivElement | null>(null)
  const w4TaxFormBoxRef = useRef<HTMLDivElement | null>(null)
  const i9TaxFormBoxRef = useRef<HTMLDivElement | null>(null)
  const secondaryFormOfIDBoxRef = useRef<HTMLDivElement | null>(null)
  const miscellaneousDocumentBoxRef = useRef<HTMLDivElement | null>(null)
  const stateLicenseAndFemaCertificationBoxRef = useRef<HTMLDivElement | null>(
    null
  )
  const licensesBoxRef = useRef<HTMLDivElement | null>(null)
  const certificationsBoxRef = useRef<HTMLDivElement | null>(null)

  const {
    onOpen: onFlagDocumentModalOpen,
    isOpen: isFlagDocumentModalOpen,
    onClose: onFlagDocumentModalClose,
  } = useDisclosure()
  const {
    isOpen: jobsListModalIsOpen,
    onOpen: onOpenJobsListModal,
    onClose: onCloseJobsListModal,
  } = useDisclosure()
  const {
    isOpen: adminNotificationModalIsOpen,
    onOpen: onOpenAdminNotificationModal,
    onClose: onCloseAdminNotificationModal,
  } = useDisclosure()
  const {
    isOpen: adminMessageModalIsOpen,
    onOpen: onOpenAdminMessageModal,
    onClose: onCloseAdminMessageModal,
  } = useDisclosure()
  const {
    isOpen: niprPDFModalIsOpen,
    onOpen: onOpenANiprPDFMModal,
    onClose: onCloseniprPDFMModal,
  } = useDisclosure()
  const {
    isOpen: testimonialsConfigModalIsOpen,
    onOpen: onOpenTestimonialsConfigMModal,
    onClose: onCloseTestimonialsConfigMModal,
  } = useDisclosure()
  const [documentToFlag, setDocumentToFlag] = useState<
    | {
        key: keyof User
        index: number
        flagReason?: string
        approved?: boolean
      }
    | undefined
  >()
  const [isUpdating, setIsUpdating] = useState<boolean>(false)
  const [update, setUpdate] = useState<boolean>(false)
  const [adminAdjusterChatRetrieved, setAdminUserChatRetrieved] =
    useState<boolean>(false)
  const [jobsRetrieved, setJobsRetrieved] = useState<boolean>(false)
  const [rostersRetrieved, setRostersRetrieved] = useState<boolean>(false)
  const [adminAdjusterChat, setAdminUserChat] = useState<Chat | undefined>()
  const [jobs, setJobs] = useState<Job[]>([])
  const [rosters, setRosters] = useState<Roster[]>([])
  const [reviewedRosters, setReviewedRosters] = useState<Roster[]>([])
  const [firms, setFirms] = useState<AdminFirm[]>([])
  const [applications, setApplications] = useState<ApplicationAndJobTitle[]>([])
  const [invitations, setInvitations] = useState<InvitationAndJobTitle[]>([])
  const [adjusters, setAdjusters] = useState<AdminAdjuster[]>([])
  const [adjustersMapTabIndex, setAdjustersMapTabIndex] = useState(0)
  const [jobsTab, setJobsTab] = useState(0)
  const [firmsMapTabIndex, setFirmsMapTabIndex] = useState(0)
  const [updateUserMutation] = useMutation(
    UPDATE_USER_BY_EMAIL(profileUser?.email)
  )
  const [createNotificationMutation] = useMutation(CREATE_NOTIFICATION)
  const [createChatMutation] = useMutation(CREATE_CHAT)
  const [pushMessagetMutation] = useLazyQuery(PUSH_MESSAGE)
  const [pullDeletedByChatMutation] = useLazyQuery(PULL_DELETED_BY_CHAT)

  const userSortedLicenses: LicenseCertification[] = sortByName([
    ...(profileUser?.licenses ?? []),
  ])
  const userSortedCertifications: LicenseCertification[] = sortByName([
    ...(profileUser?.certifications ?? []),
  ])

  const userSortedCertificationRquirements = sortByName([
    ...(profileUser?.additionalCertificationRequirements ?? []),
  ])

  const { stateLicenses }: StateLicenseContextType =
    useContext(StateLicesesContext)
  const { trainingCertifications }: TrainingCertificationContextType =
    useContext(TrainingCertificationsContext)
  const { loas }: LOASContextType = useContext(LOASContext)

  const [status, setStatus] = useState<USER_STATUS>(
    profileUser?.status ?? USER_STATUS.AVAILABLE
  )
  const [availabilityDate, setAvailabilityDate] = useState<string>(
    profileUser?.availabilityDate ?? ''
  )
  const [availabilityInfo, setAvailabilityInfo] = useState<string>(
    profileUser?.availabilityInfo ?? ''
  )

  const [areasOfOperation, setAreasOfOperation] = useState<string[]>(
    profileUser?.areasOfOperation ? [...profileUser?.areasOfOperation] : []
  )

  const [selectedLicenseForPDF, setSelectedLicenseForPDF] = useState<
    LicenseCertification | undefined
  >()

  const preferredLocations = useMemo(() => {
    return !profileUser?.jobLocations?.length
      ? ['No locations']
      : profileUser.jobLocations.map((jl) => {
          return US_STATES.find((s) => s.abbreviation === jl)?.name
        })
  }, [profileUser?.jobLocations])

  const [getAdminUserChat] = useLazyQuery(GET_ADMIN_USER_CHAT, {
    onCompleted: (data: { AdminUserChat: Chat }) => {
      setAdminUserChatRetrieved(true)
      if (data?.AdminUserChat) {
        const j = data?.AdminUserChat
        setAdminUserChat({ ...j })
      }
    },
    onError: (err) => {},
  })

  const [getFirmJobsRostersByIds] = useLazyQuery(GET_ROSTERS_BY_FILTER, {
    onCompleted: (data: { RostersByFilter: Roster[] }) => {
      //   console.log(data)
      if (data?.RostersByFilter) {
        const r = data?.RostersByFilter
        setRosters(r)
      }
    },
    onError: (err) => {},
  })

  const [getFirmJobs] = useLazyQuery(GET_ALL_FIRM_JOBS, {
    onCompleted: (data: { GetJobMany: Job[] }) => {
      setJobsRetrieved(true)
      if (data?.GetJobMany) {
        const j = data?.GetJobMany
        setJobs([...j])
        const jobsIds = j.map((a) => a._id)
        getFirmJobsRostersByIds({
          variables: { jobsIds },
          fetchPolicy: 'network-only',
        })
      }
    },
    onError: (err) => {},
  })

  const [getAllAdjusters] = useLazyQuery(GET_ADJUSTERS_BY_FILTERS, {
    onCompleted: (data: { AdjustersByFilter: AdminAdjuster[] }) => {
      if (data?.AdjustersByFilter) {
        const newUsers = [...data?.AdjustersByFilter]
        setAdjusters(newUsers)
      }
    },
    onError: (err) => {
      console.log(err)
    },
  })

  const [getFirmsByIds] = useLazyQuery(GET_FIRMS_BY_FILTERS, {
    onCompleted: (data: { FirmsByFilter: AdminFirm[] }) => {
      if (data?.FirmsByFilter) {
        setFirms([...data?.FirmsByFilter])
      }
    },
    onError: (err) => {
      console.log(err)
    },
  })

  const [getInvitations] = useLazyQuery(GET_INVITATIONS_AND_TITLE, {
    onCompleted: (data: {
      InvitationsAndJobTitle: InvitationAndJobTitle[]
    }) => {
      if (data?.InvitationsAndJobTitle) {
        const invitations = data?.InvitationsAndJobTitle
        setInvitations([...invitations])
      }
    },
    onError: (err) => {},
  })

  const [getJobsByIds] = useLazyQuery(GET_JOBS_BY_IDS, {
    onCompleted: (data: { JobsByIds: Job[] }) => {
      if (data?.JobsByIds) {
        const j = data?.JobsByIds
        setJobs([...j])

        const ids = j.map((j) => j.firmId)
        getFirmsByIds({ variables: { ids }, fetchPolicy: 'network-only' })
      }
    },
    onError: (err) => {},
  })

  const [getAllAdjusterRosters] = useLazyQuery(GET_ROSTERS, {
    onCompleted: (data: { RosterMany: Roster[] }) => {
      setRostersRetrieved(true)
      if (data?.RosterMany) {
        const r = data?.RosterMany
        const rr = [...r]
        setRosters(rr)
        setReviewedRosters(rr.filter((r) => !!r.review))
        const jobsIds = r.map((a) => a.jobId)
        // console.log({ jobsIds })
        getJobsByIds({ variables: { jobsIds }, fetchPolicy: 'network-only' })
      }
    },
    onError: (err) => {},
  })

  const [getApplications] = useLazyQuery(GET_APPLICATIONS_AND_TITLE, {
    onCompleted: (data: {
      ApplicationsAndJobTitle: ApplicationAndJobTitle[]
    }) => {
      if (data?.ApplicationsAndJobTitle) {
        const applications = data?.ApplicationsAndJobTitle
        setApplications([...applications])
      }
    },
    onError: (err) => {},
  })

  useEffect(() => {
    if (!adminAdjusterChatRetrieved && editorIsAdmin) {
      getAdminUserChat({
        variables: { userId: profileUser?._id },
        fetchPolicy: 'network-only',
      })
    }
  }, [
    adminAdjusterChatRetrieved,
    editorIsAdmin,
    getAdminUserChat,
    profileUser?._id,
  ])

  useEffect(() => {
    if (
      !jobsRetrieved &&
      profileUser &&
      profileUser.userType === UserType.FIRM
    ) {
      getFirmJobs({
        variables: {
          firmId: profileUser?._id,
          statuses: [JobStatus.ONGOING, JobStatus.FINISHED],
        },
        fetchPolicy: 'network-only',
      })
      getAllAdjusters()
    }
  }, [getAllAdjusters, getFirmJobs, jobsRetrieved, profileUser])

  useEffect(() => {
    if (
      !rostersRetrieved &&
      profileUser &&
      profileUser.userType === UserType.ADJUSTER
    ) {
      getAllAdjusterRosters({
        variables: { adjusterId: profileUser._id },
        fetchPolicy: 'network-only',
      })
      getApplications({
        variables: { adjusterId: profileUser._id },
        fetchPolicy: 'network-only',
      })
      getInvitations({
        variables: { adjusterId: profileUser._id },
        fetchPolicy: 'network-only',
      })
    }
  }, [
    getAllAdjusterRosters,
    getApplications,
    getInvitations,
    rostersRetrieved,
    profileUser,
  ])

  useEffect(() => {
    const queryParams = searchParams.getAll('section')
    if (queryParams.length > 0) {
      const sectionName = queryParams[0] as keyof User
      switch (sectionName) {
        case 'licenses':
          licensesBoxRef.current?.scrollIntoView()
          break
        case 'certifications':
          certificationsBoxRef.current?.scrollIntoView()
          break
        case 'femaCertification':
          stateLicenseAndFemaCertificationBoxRef.current?.scrollIntoView()
          break
        case 'driverLicense':
          driversLicenseBoxRef.current?.scrollIntoView()
          break
        case 'w9TaxForm':
          w9TaxFormBoxRef.current?.scrollIntoView()
          break
        case 'w4TaxForm':
          w4TaxFormBoxRef.current?.scrollIntoView()
          break
        case 'i9TaxForm':
          i9TaxFormBoxRef.current?.scrollIntoView()
          break
        case 'secondaryFormOfID':
          secondaryFormOfIDBoxRef.current?.scrollIntoView()
          break
        case 'miscelaneosDocument':
          miscellaneousDocumentBoxRef.current?.scrollIntoView()
          break
        default:
          break
      }
    }
  }, [searchParams])

  // every user that is not an admin and is visiting others users profiles has to see a redacted profile
  const showNonRedactedProfile = useMemo(() => {
    const adjusterFinishedJobsIds = rosters
      .filter((r) => r.status !== ADJUSTER_WORK_STATUS.FINISHED)
      .map((r) => r.jobId)
    // console.log({ adjusterFinishedJobsIds })
    const jobsWhereAdjusterHasFinishedItsJob = jobs.filter((job) =>
      adjusterFinishedJobsIds.includes(job?._id ?? '')
    )
    const firmCanSeeAdjusterProfile = !!jobsWhereAdjusterHasFinishedItsJob.find(
      (j) => j.firmId === viewer?._id
    )

    return (!canEditProfile && firmCanSeeAdjusterProfile) || canEditProfile
  }, [canEditProfile, jobs, rosters, viewer?._id])

  const jobsWhichWorkIsCompleted = useMemo(() => {
    const finishedRosters = rosters.filter(
      (roster) => roster.status === ADJUSTER_WORK_STATUS.FINISHED
    )
    return jobs.filter(
      (job) => !!finishedRosters.find((r) => r.jobId === job._id)
    )
  }, [jobs, rosters])

  const jobsWhichWorkIsIncomplete = useMemo(() => {
    const ongoingRosters = rosters.filter(
      (roster) => roster.status === ADJUSTER_WORK_STATUS.ONGOING
    )
    return jobs.filter(
      (job) => !!ongoingRosters.find((r) => r.jobId === job._id)
    )
  }, [jobs, rosters])

  const rostersCreatedWithinTheLast30Days = useMemo(() => {
    const today = new Date()
    return rosters.filter((roster) => {
      const rosterDate = new Date((roster as any)?.createdAt)
      const diffTime = Math.abs(today.getTime() - rosterDate.getTime())
      const diffDays = diffTime / (1000 * 60 * 60 * 24)
      //   console.log(
      //     today,
      //     (roster as any)?.createdAt,
      //     '--------',
      //     diffDays,
      //     diffDays <= 30
      //   )
      return diffDays <= 30
    })
  }, [rosters])

  const rating = useMemo((): number => {
    const reviewdRosters = rosters.filter((r) => !!r.review)
    const reviewdRostersCount = reviewdRosters.length
    if (reviewdRostersCount === 0) return 0

    const averageReviews = reviewdRosters.reduce((total, roster) => {
      const survey = roster?.review?.survey
      if (survey) {
        // JOB_REVIEW_FEEDBACK_SCORE
        let countableAnswers = 0
        const summary = Object.keys(survey).reduce((t, q) => {
          if (survey[q as keyof ReviewSurvey] !== JOB_REVIEW_FEEDBACK.NA)
            countableAnswers++
          const a = survey[q as keyof ReviewSurvey] ?? JOB_REVIEW_FEEDBACK.NA
          return t + JOB_REVIEW_FEEDBACK_SCORE[a]
        }, 0)

        return total + summary / countableAnswers
      }

      //THIS WONT HAPPEN BECAUSE OF THE FILTER FROM THE FIRST LINE OF THIS FUNCTION
      return -1
    }, 0)

    return averageReviews / reviewdRostersCount
  }, [rosters])

  const deployabilityScore = useMemo(() => {
    const approvedStateLicensesCount = (profileUser?.licenses ?? []).filter(
      (license) => license.approved
    ).length

    const approvedCertificationsCount = (
      profileUser?.certifications ?? []
    ).filter((certification) => certification.approved).length

    const statelicensesPoints =
      approvedStateLicensesCount >= 1 && approvedStateLicensesCount <= 5
        ? 0.1
        : approvedStateLicensesCount >= 6 && approvedStateLicensesCount <= 10
        ? 0.2
        : approvedStateLicensesCount >= 11 && approvedStateLicensesCount <= 15
        ? 0.3
        : approvedStateLicensesCount >= 16 && approvedStateLicensesCount <= 20
        ? 0.4
        : approvedStateLicensesCount > 20
        ? 0.5
        : 0

    const femaCertificationPoints =
      profileUser?.femaCertification?.categories &&
      profileUser?.femaCertification?.categories?.length > 0
        ? profileUser.femaCertification.categories.reduce((prev, cat) => {
            if (
              [
                FEMA_CATEGORY.RESIDENTIAL,
                FEMA_CATEGORY.SMALL_COMMERCIAL,
                FEMA_CATEGORY.MOBILE_HOME,
              ].includes(cat)
            )
              return prev + 0.1

            if (FEMA_CATEGORY.LARGE_COMMERCIAL === cat) return prev + 0.1
            if (FEMA_CATEGORY.RESIDENTIAL_CONDO === cat) return prev + 0.1 //RCBAP

            return 0
          }, 0)
        : 0

    const certificationsPoints =
      approvedCertificationsCount >= 1 && approvedCertificationsCount <= 5
        ? 0.1
        : approvedCertificationsCount > 5
        ? 0.2
        : 0
    const yearsOfExperiencePoints = !profileUser?.yearsOfLicensedExperience
      ? 0
      : profileUser.yearsOfLicensedExperience >= 1 &&
        profileUser.yearsOfLicensedExperience <= 3
      ? 0.1
      : profileUser.yearsOfLicensedExperience >= 4 &&
        profileUser.yearsOfLicensedExperience <= 6
      ? 0.2
      : profileUser.yearsOfLicensedExperience >= 7 &&
        profileUser.yearsOfLicensedExperience <= 9
      ? 0.3
      : 0.4

    const atListOneApprovedHRDocument =
      profileUser?.driverLicense?.approved ||
      profileUser?.w9TaxForm?.approved ||
      profileUser?.i9TaxForm?.approved ||
      profileUser?.secondaryFormOfID?.approved ||
      profileUser?.miscelaneosDocument?.approved
    const baselineScore = atListOneApprovedHRDocument ? 3 : 0

    const f =
      (baselineScore +
        statelicensesPoints +
        femaCertificationPoints +
        certificationsPoints +
        yearsOfExperiencePoints) *
      20
    return _.isNaN(f) || !_.isFinite(f) ? 0 : f > 100 ? 100 : Math.floor(f)
  }, [
    profileUser?.certifications,
    profileUser?.driverLicense?.approved,
    profileUser?.femaCertification?.categories,
    profileUser?.i9TaxForm?.approved,
    profileUser?.licenses,
    profileUser?.miscelaneosDocument?.approved,
    profileUser?.secondaryFormOfID?.approved,
    profileUser?.w9TaxForm?.approved,
    profileUser?.yearsOfLicensedExperience,
  ])

  const deployabilityScoreShield = useMemo(() => {
    if (deployabilityScore >= 80)
      return <BsShieldCheck fontSize={100} color={ADJUSTANT_GREEN} />
    if (deployabilityScore >= 50)
      return <BsShieldCheck fontSize={100} color="#c79e3e" />
    return <BsShieldExclamation fontSize={100} color="#d10606" />
  }, [deployabilityScore])

  const firmJobsStates = useMemo(() => {
    const filter =
      firmsMapTabIndex === 0 ? JobStatus.ONGOING : JobStatus.FINISHED
    const r = jobs
      .filter((job) => job.status === filter)
      .map((job) => job.state ?? '')
    return r
  }, [firmsMapTabIndex, jobs])

  const adjusterLicensesOrCertificationsStates = useMemo(() => {
    if (adjustersMapTabIndex === 0) {
      return (
        profileUser?.licenses?.map(
          (l) => US_STATES.find((s) => s.abbreviation === l.state)?.name ?? ''
        ) ?? []
      )
    } else {
      return (
        profileUser?.certifications?.map(
          (c) => US_STATES.find((s) => s.abbreviation === c.state)?.name ?? ''
        ) ?? []
      )
    }
  }, [adjustersMapTabIndex, profileUser?.certifications, profileUser?.licenses])

  const updateAvailability = useCallback(async () => {
    setIsUpdating(true)
    if (profileUser) {
      const partialUser: Partial<User> = {
        status,
        ...(availabilityDate ? { availabilityDate } : {}),
        availabilityInfo,
      }

      //   console.log('PROFILE TO UPDATE: ', partialUser)
      const result = await updateUserMutation({
        variables: { user: partialUser },
      })

      if (result?.data) {
        const newUser = { ...profileUser, ...partialUser }
        setProfileUser(newUser)
        if (!editorIsAdmin)
          setItemToLocalStorage(LOCALSTORAGE_OBJECTS_NAMES.USER, newUser)
        showSuccessToast('Availability Updated!')
      }
    }
    setIsUpdating(false)
    setUpdate(false)
  }, [
    profileUser,
    status,
    availabilityDate,
    availabilityInfo,
    updateUserMutation,
    setProfileUser,
    editorIsAdmin,
  ])

  const updateTestimonialsConfig = useCallback(
    async (newConfig: TestimonialsConfig) => {
      setIsUpdating(true)
      if (profileUser) {
        const partialUser: Partial<User> = {
          testimonialsConfig: newConfig,
        }

        //   console.log('PROFILE TO UPDATE: ', partialUser)
        const result = await updateUserMutation({
          variables: { user: partialUser },
        })

        if (result?.data) {
          const newUser = { ...profileUser, ...partialUser }
          setProfileUser(newUser)
          if (!editorIsAdmin)
            setItemToLocalStorage(LOCALSTORAGE_OBJECTS_NAMES.USER, newUser)
          showSuccessToast('Testimonials Config Updated!')
        }
      }
      setIsUpdating(false)
      setUpdate(false)
    },
    [profileUser, updateUserMutation, setProfileUser, editorIsAdmin]
  )

  const updateAreasOfOperation = useCallback(async () => {
    setIsUpdating(true)
    if (profileUser) {
      const partialUser: Partial<User> = {
        areasOfOperation,
      }
      console.log('PROFILE TO UPDATE: ', partialUser)
      const result = await updateUserMutation({
        variables: { user: partialUser },
      })

      if (result?.data) {
        const newUser = { ...profileUser, ...partialUser }
        setProfileUser(newUser)
        if (!editorIsAdmin)
          setItemToLocalStorage(LOCALSTORAGE_OBJECTS_NAMES.USER, newUser)
      }
    }
    setIsUpdating(false)
    setUpdate(false)
  }, [
    profileUser,
    areasOfOperation,
    updateUserMutation,
    editorIsAdmin,
    setProfileUser,
  ])

  const updateFlaggedDocument = useCallback(
    async (flagReason: string, flagMessage: string, approved: boolean) => {
      setIsUpdating(true)
      console.log(profileUser, documentToFlag?.key)
      if (profileUser && documentToFlag?.key) {
        let partialUser: Partial<User> = {}
        let message = ''
        const m = approved ? 'approved' : flagReason ? 'flagged' : ''

        // console.log(documentToFlag)
        if (documentToFlag?.key === 'licenses') {
          if (documentToFlag?.index >= 0) {
            const licenses = [...userSortedLicenses]
            licenses[documentToFlag.index] = {
              ...licenses[documentToFlag.index],
              flag: flagReason,
              approved,
            }
            partialUser = { licenses }
            message = `Your additional license ${
              licenses[documentToFlag.index].name
            } has been ${m}${flagMessage ? ': ' + flagMessage : ''}`
          }
        }

        if (documentToFlag?.key === 'certifications') {
          if (documentToFlag?.index >= 0) {
            const certifications = [...userSortedCertifications]
            certifications[documentToFlag.index] = {
              ...certifications[documentToFlag.index],
              flag: flagReason,
              approved,
            }
            partialUser = { certifications }
            message = message = `Your additional certification ${
              certifications[documentToFlag.index].name
            } has been ${m}${flagMessage ? ': ' + flagMessage : ''}`
          }
        }

        if (documentToFlag?.key === 'femaCertification') {
          const femaCertification = {
            ...profileUser.femaCertification,
            has: !!profileUser.femaCertification?.has,
            expiryDate: profileUser?.femaCertification?.expiryDate ?? '',
            issuedDate: profileUser?.femaCertification?.issuedDate ?? '',
            flag: flagReason,
            approved,
          }
          partialUser = { femaCertification }

          message = `Your FEMA certification has been ${m}${
            flagMessage ? ': ' + flagMessage : ''
          }`
        }

        if (documentToFlag?.key === 'driverLicense') {
          const driverLicense = {
            ...profileUser.driverLicense,
            id: profileUser?.driverLicense?.id ?? '',
            address: profileUser?.driverLicense?.address ?? '',
            city: profileUser?.driverLicense?.city ?? '',
            state: profileUser?.driverLicense?.state ?? '',
            zipcode: profileUser?.driverLicense?.zipcode ?? '',
            birthDate: profileUser?.driverLicense?.birthDate ?? '',
            url: profileUser?.driverLicense?.url ?? '',
            flag: flagReason,
            approved,
          }
          partialUser = { driverLicense }

          message = `Your Driver's License has been ${m}${
            flagMessage ? ': ' + flagMessage : ''
          }`
        }

        if (documentToFlag?.key === 'w9TaxForm') {
          const w9TaxForm = {
            ...profileUser?.w9TaxForm,
            url: profileUser?.w9TaxForm?.url ?? '',
            flag: flagReason,
            approved,
          }
          partialUser = { w9TaxForm }
          message = `Your W9 Tax Form has been ${m}${
            flagMessage ? ': ' + flagMessage : ''
          }`
        }

        if (documentToFlag?.key === 'w4TaxForm') {
          const w4TaxForm = {
            ...profileUser?.w4TaxForm,
            url: profileUser?.w4TaxForm?.url ?? '',
            flag: flagReason,
            approved,
          }
          partialUser = { w4TaxForm }
          message = `Your W4 Tax Form has been ${m}${
            flagMessage ? ': ' + flagMessage : ''
          }`
        }

        if (documentToFlag?.key === 'i9TaxForm') {
          const i9TaxForm = {
            ...profileUser?.i9TaxForm,
            url: profileUser?.i9TaxForm?.url ?? '',
            flag: flagReason,
            approved,
            additionalDocumentsUrls: [],
          }
          partialUser = { i9TaxForm }
          message = `Your I-9 Employment Eligibility Form has been ${m}${
            flagMessage ? ': ' + flagMessage : ''
          }`
        }

        if (documentToFlag?.key === 'secondaryFormOfID') {
          const secondaryFormOfID = {
            ...profileUser?.secondaryFormOfID,
            url: profileUser?.secondaryFormOfID?.url ?? '',
            flag: flagReason,
            approved,
          }
          partialUser = { secondaryFormOfID }
          message = `Your Secondary Form Of ID has been ${m}${
            flagMessage ? ': ' + flagMessage : ''
          }`
        }

        if (documentToFlag?.key === 'miscelaneosDocument') {
          const miscelaneosDocument = {
            ...profileUser?.miscelaneosDocument,
            url: profileUser?.miscelaneosDocument?.url ?? '',
            description: profileUser?.miscelaneosDocument?.description ?? '',
            flag: flagReason,
            approved,
          }
          partialUser = { miscelaneosDocument }

          message = `Your Miscellaneous Document has been ${m}${
            flagMessage ? ': ' + flagMessage : ''
          }`
        }

        console.log('PROFILE TO UPDATE: ', partialUser)
        const result = await updateUserMutation({
          variables: { user: partialUser },
        })

        if (result?.data) {
          const newUser = { ...profileUser, ...partialUser }
          setProfileUser(newUser)
          //   if (flagReason) {
          createNotificationMutation({
            variables: {
              notification: {
                userId: profileUser?._id,
                message,
                link: `${GLOBAL_ROUTES.PROFILE}?section=${documentToFlag?.key}`,
                read: false,
              },
            },
          })
          //   }
        }
      }
      setIsUpdating(false)
      setUpdate(false)
    },
    [
      profileUser,
      documentToFlag,
      updateUserMutation,
      userSortedLicenses,
      userSortedCertifications,
      setProfileUser,
      createNotificationMutation,
    ]
  )

  const getAdminDocumentIcon = useCallback(
    (flag?: string, approved?: boolean) => {
      return approved ? (
        <AiOutlineCheck />
      ) : flag ? (
        <BsFlagFill />
      ) : (
        <AiFillEye />
      )
    },
    []
  )

  const getAdminDocumentColor = useCallback(
    (flag?: string, approved?: boolean) => {
      return approved ? 'green' : flag ? 'red' : 'yellow.500'
    },
    []
  )

  const sendAdminNotification = useCallback(
    (message: string) => {
      createNotificationMutation({
        variables: {
          notification: {
            userId: profileUser?._id,
            message: `A new message from the admin: ${message}`,
            link: ``,
            read: false,
          },
        },
      })
      showSuccessToast('Notification Sent!')
    },
    [createNotificationMutation, profileUser?._id]
  )

  const sendAdminMessage = useCallback(
    async (message: string) => {
      const newMessage = {
        id: `${Date.now()}`,
        message,
        date: new Date().toString(),
        sender: UserType.ADMIN,
        read: false,
      }
      console.log(adminAdjusterChat)
      if (adminAdjusterChat) {
        await pushMessagetMutation({
          variables: {
            message: newMessage,
            id: adminAdjusterChat._id,
          },
        })
        adminAdjusterChat.messages = [...adminAdjusterChat.messages, newMessage]

        //Deletes the receiver from deletedBy so he can read the last message sent to him
        const receiverId =
          adminAdjusterChat.users[0] === UserType.ADMIN
            ? adminAdjusterChat.users[1]
            : adminAdjusterChat.users[0]
        if (adminAdjusterChat.deletedBy.includes(receiverId)) {
          pullDeletedByChatMutation({
            variables: {
              userId: receiverId,
              id: adminAdjusterChat._id,
            },
          })

          adminAdjusterChat.deletedBy = []
        }

        setAdminUserChat({ ...adminAdjusterChat })
      } else {
        //create the chat with the message
        const newChat = {
          jobId: '',
          status: CHAT_STATUS.ACTIVE,
          users: [UserType.ADMIN, profileUser?._id],
          deletedBy: [],
          messages: [newMessage],
          pinnedBy: [],
        }

        const result = await createChatMutation({
          variables: {
            chat: newChat,
          },
        })

        if (result?.data?.ChatCreateOne)
          setAdminUserChat(result?.data?.ChatCreateOne?.record)
      }

      showSuccessToast('Message Sent!')
    },
    [
      adminAdjusterChat,
      createChatMutation,
      pullDeletedByChatMutation,
      pushMessagetMutation,
      profileUser?._id,
    ]
  )

  return (
    <Box paddingX={['10px', '50px']} paddingY="10px">
      <JobsListModal
        jobs={
          jobsTab === 0 ? jobsWhichWorkIsIncomplete : jobsWhichWorkIsCompleted
        }
        rosters={rosters}
        adjusters={adjusters}
        isOpen={jobsListModalIsOpen}
        onClose={onCloseJobsListModal}
        tab={jobsTab}
        showRostePopover={false}
      />

      <SendMessageModal
        header="Send a Notification"
        isOpen={adminNotificationModalIsOpen}
        onClose={(message) => {
          onCloseAdminNotificationModal()
          if (message) sendAdminNotification(message)
        }}
      />

      <SendMessageModal
        isOpen={adminMessageModalIsOpen}
        onClose={(message) => {
          onCloseAdminMessageModal()
          if (message) sendAdminMessage(message)
        }}
      />

      <NIPRPDFModal
        license={selectedLicenseForPDF}
        user={profileUser}
        isOpen={niprPDFModalIsOpen}
        onClose={() => {
          onCloseniprPDFMModal()
        }}
      />

      {isFlagDocumentModalOpen && (
        <FlagDocumentModal
          flagReason={documentToFlag?.flagReason}
          isApproved={documentToFlag?.approved}
          isOpen={isFlagDocumentModalOpen}
          onClose={(r, q, a) => {
            console.log(r, q, a)
            onFlagDocumentModalClose()
            if (typeof r === 'string') {
              updateFlaggedDocument(r, q ?? '', !!a)
            }
          }}
        />
      )}

      <UpdateTestimonialsConfigModal
        jobsWhichWorkIsCompleted={jobsWhichWorkIsCompleted}
        firms={firms}
        rosters={reviewedRosters}
        config={profileUser?.testimonialsConfig}
        isOpen={testimonialsConfigModalIsOpen}
        onClose={(t, newConfig) => {
          onCloseTestimonialsConfigMModal()
          if (t && newConfig) {
            console.log({ newConfig })
            updateTestimonialsConfig(newConfig)
          }
        }}
      />

      {/* MAIN SECTION */}
      <Box borderRadius={8} border="1px solid #ECEAE9" mb={2}>
        {/* Avatar */}
        <Box position="relative" zIndex={2}>
          <Box position="absolute" top={28} left={10} height={24} width={24}>
            <Avatar
              size="full"
              src={
                profileUser?.profilePicture || '/images/no_profile_picture.png'
              }
            />
          </Box>
        </Box>

        {/* Banner and buttons */}
        <Box
          width="100%"
          height="180px"
          background={`linear-gradient(91.49deg, #68A5A1 0%, ${ADJUSTANT_GREEN} 100%)`}
          display="flex"
          justifyContent="end"
          alignItems="end"
          //   p={4}
          borderTopRadius={8}
          position="relative"
        >
          {profileUser?.bannerPicture && (
            <Image
              w="100%"
              h="100%"
              borderRadius="inherit"
              src={profileUser.bannerPicture}
              position="absolute"
            />
          )}
          <Box mr={4} mb={4}>
            {editorIsAdmin && (
              <>
                <Button mr={2} onClick={() => onOpenAdminMessageModal()}>
                  Send Message
                </Button>
                <Button mr={2} onClick={() => onOpenAdminNotificationModal()}>
                  Send Notification
                </Button>
              </>
            )}
            {canEditProfile && (
              <Button
                onClick={() => {
                  navigate(
                    `${editorIsAdmin ? GLOBAL_ROUTES.ADMIN : ''}${
                      GLOBAL_ROUTES.UPDATE_PROFILE
                    }${editorIsAdmin ? '/' + profileUser?._id : ''}`
                  )
                }}
              >
                Update Profile
              </Button>
            )}
          </Box>
        </Box>
        <Box
          width="100%"
          minHeight="120px"
          background="white"
          py={10}
          px={8}
          borderBottomRadius={8}
        >
          <Heading as="h2" mb={2}>
            {profileUser?.userType === UserType.FIRM
              ? profileUser?.firmName || 'Firm name not established'
              : showNonRedactedProfile
              ? profileUser?.firstName + ' ' + profileUser?.lastName
              : profileUser?.firstName + ' ' + profileUser?.lastName?.[0]}
          </Heading>
          {showNonRedactedProfile && (
            <Text as="p" color="gray.500">
              {profileUser?.location || 'No location'}
            </Text>
          )}
        </Box>
      </Box>

      {/* Years of Licensed Experience  */}
      {profileUser?.userType === UserType.ADJUSTER && (
        <Box
          py={10}
          px={8}
          background="white"
          borderRadius={8}
          border="1px solid #ECEAE9"
          mb={2}
        >
          <Heading as="h4" size="md" color="gray.500" mb={2}>
            Years of Licensed Experience
          </Heading>
          <Text>{profileUser?.yearsOfLicensedExperience}</Text>
        </Box>
      )}

      {/* ABOUT */}
      {/* {user?.userType === UserType.ADJUSTER && ( */}
      <Box
        py={10}
        px={8}
        background="white"
        borderRadius={8}
        border="1px solid #ECEAE9"
        mb={2}
      >
        <Heading as="h4" size="md" color="gray.500" mb={2}>
          {profileUser?.userType === UserType.ADJUSTER
            ? 'Adjusting Experience Description'
            : 'About'}
        </Heading>
        <Text>
          {' '}
          {profileUser?.bio ||
            (profileUser?.userType === UserType.ADJUSTER
              ? 'No description'
              : 'No bio')}
        </Text>
      </Box>
      {/* )} */}

      {/* Additional Info */}
      {profileUser?.userType === UserType.ADJUSTER &&
        showNonRedactedProfile && (
          <Box
            py={10}
            px={8}
            background="white"
            borderRadius={8}
            border="1px solid #ECEAE9"
            mb={2}
            ref={driversLicenseBoxRef}
          >
            <Heading as="h4" size="md" color="gray.500" mb={2}>
              Additional Info
            </Heading>

            <Heading as="h4" size="sm" color="gray.500">
              Language Preference
            </Heading>
            <Text mb={2}>{profileUser?.languagePreference ?? '-'}</Text>

            <Heading as="h4" size="sm" color="gray.500">
              Emergency Contact
            </Heading>
            <Text mb={2}>{profileUser?.emergencyContact ?? '-'}</Text>

            <Heading as="h4" size="sm" color="gray.500">
              Xactimate ID
            </Heading>
            <Text mb={2}>{profileUser?.xactimateId ?? '-'}</Text>

            <Heading as="h4" size="sm" color="gray.500">
              Company Alias
            </Heading>
            <Text mb={2}>{profileUser?.companyAlias ?? '-'}</Text>

            <Heading as="h4" size="sm" color="gray.500">
              Phone Number
            </Heading>
            <Text mb={2}>{profileUser?.phoneNumber ?? '-'}</Text>
          </Box>
        )}

      {/* Experience and FEDDBACK SUMMARY */}
      {profileUser?.userType === UserType.ADJUSTER && (
        <Box mb={2}>
          <Stack
            spacing={2}
            display="flex"
            direction={['column', 'column', 'row']}
          >
            {/* Jobs history */}
            <Stack
              width={['100%', '100%', '33.3%']}
              pt={8}
              pb={4}
              pl={8}
              pr={2}
              background="white"
              borderRadius={8}
              border="1px solid #ECEAE9"
              h={300}
            >
              {/* <Stack height="50%" overflowY="auto">
                <Heading as="h4" size="md" color="gray.500" mb={2}>
                  Completed Jobs
                </Heading>
                <Box
                  height="-webkit-fill-available"
                  overflowY="scroll"
                  display="contents"
                >
                  {jobsWhichWorkIsCompleted.map((job) => (
                    <Text
                      key={job._id}
                      as="b"
                      fontSize="sm"
                      mb={1}
                      cursor="pointer"
                      onClick={() =>
                        navigate(`${GLOBAL_ROUTES.JOB}/${job._id}`)
                      }
                    >
                      -{job.title}
                    </Text>
                  ))}
                </Box>
              </Stack>
              <Stack height="50%" overflowY="auto">
                <Heading as="h4" size="md" color="gray.500" mb={2}>
                  Current Jobs
                </Heading>
                <Box
                  height="-webkit-fill-available"
                  overflowY="scroll"
                  display="contents"
                >
                  {jobsWhichWorkIsIncomplete.map((job) => (
                    <Text
                      key={job._id}
                      as="b"
                      fontSize="sm"
                      mb={1}
                      cursor="pointer"
                      onClick={() =>
                        navigate(`${GLOBAL_ROUTES.JOB}/${job._id}`)
                      }
                    >
                      -{job.title}
                    </Text>
                  ))}
                </Box>
              </Stack> */}

              {/* <Stack height="100%" overflowY="auto">
                <Heading as="h4" size="md" color="gray.500" mb={2}>
                  Queue
                </Heading>
                <Box
                  height="-webkit-fill-available"
                  overflowY="scroll"
                  display="contents"
                >
                  <Text as="b" fontSize={18}>
                    Jobs accepted within 30 days of the report date:
                  </Text>
                  {rostersCreatedWithinTheLast30Days.map((roster) => (
                    <Text
                      key={roster._id}
                      //   as="b"
                      fontSize="sm"
                      //   mb={1}
                      cursor="pointer"
                      onClick={() =>
                        navigate(`${GLOBAL_ROUTES.JOB}/${roster.jobId}`)
                      }
                    >
                      - {jobs.find((job) => job._id === roster.jobId)?.title} -{' '}
                      {formatDate((roster as any)?.createdAt ?? '')}
                    </Text>
                  ))}
                </Box>
              </Stack> */}

              <Stack height="100%" overflowY="auto">
                <Heading as="h4" size="md" color="gray.500" mb={2}>
                  Experience in Months
                </Heading>
                <Box
                  height="-webkit-fill-available"
                  overflowY="scroll"
                  display="contents"
                >
                  {Object.keys(EXPERIENCE_TYPES).map((key, index) => (
                    <Grid templateColumns="repeat(4, 1fr)" gap={6} key={index}>
                      <GridItem
                        w="100%"
                        colSpan={3}
                        fontWeight={
                          [
                            EXPERIENCE_TYPES.propertyResidential,
                            EXPERIENCE_TYPES.auto,
                            EXPERIENCE_TYPES.commercial,
                            EXPERIENCE_TYPES.specialtyServices,
                          ].includes(EXPERIENCE_TYPES[key as keyof Experience])
                            ? 500
                            : 300
                        }
                      >
                        {EXPERIENCE_TYPES[key as keyof Experience] ?? ''}
                      </GridItem>
                      <GridItem w="100%" colSpan={1}>
                        {
                          EXPERIENCE_VALUES[
                            profileUser?.experience?.[
                              key as keyof Experience
                            ] ?? 0
                          ]
                        }
                      </GridItem>
                    </Grid>
                  ))}
                </Box>
              </Stack>
            </Stack>

            {/* Feedback */}
            <Stack
              width={['100%', '100%', '33.3%']}
              py={10}
              px={8}
              background="white"
              borderRadius={8}
              border="1px solid #ECEAE9"
              h={300}
            >
              <VStack px={4}>
                <Stack alignItems="center" my={5}>
                  {deployabilityScoreShield}
                </Stack>
                <VStack lineHeight="110%">
                  <Divider />
                  <Text fontSize="2xl" as="b" mt={2}>
                    {deployabilityScore ?? '-'}%
                  </Text>
                  <Text fontSize="sm" as="b">
                    Deployability score
                  </Text>
                </VStack>
              </VStack>
            </Stack>

            {/* Performance rating */}
            <Stack
              width={['100%', '100%', '33.3%']}
              py={4}
              px={8}
              background="white"
              borderRadius={8}
              border="1px solid #ECEAE9"
              h={300}
            >
              <VStack px={4} justifyContent="center">
                <Stack alignItems="center" my={5}>
                  <StarRating
                    rating={rating}
                    // setRating={setRating}
                    isDisabled
                  />
                </Stack>
                <VStack lineHeight="110%">
                  <Divider />
                  <Text fontSize="4xl" as="b" color={ADJUSTANT_GREEN} my={2}>
                    {truncateNumber(rating)}
                  </Text>
                  <Text fontSize="xl" as="b" color={ADJUSTANT_GREEN}>
                    PERFORMANCE RATING
                  </Text>
                </VStack>
              </VStack>
            </Stack>
          </Stack>
        </Box>
      )}

      {/* Testimonials */}
      {profileUser?.userType === UserType.ADJUSTER &&
        ((profileUser.testimonialsConfig &&
          profileUser.testimonialsConfig.show) ||
          !profileUser.testimonialsConfig) && (
          <Box
            py={10}
            px={8}
            background="white"
            borderRadius={8}
            border="1px solid #ECEAE9"
            mb={2}
            ref={driversLicenseBoxRef}
            h={300}
            overflowY="auto"
          >
            {canEditProfile && (
              <HStack mb={2} justifyContent="end">
                {/* <Tooltip
                  label={
                    !profileUser.testimonialsConfig ||
                    profileUser.testimonialsConfig.show
                      ? 'Testimonials are visible'
                      : 'Testimonials are hidden'
                  }
                >
                  <Box>
                    <Icon
                      as={
                        !profileUser.testimonialsConfig ||
                        profileUser.testimonialsConfig.show
                          ? AiFillEye
                          : AiFillEyeInvisible
                      }
                      color={ADJUSTANT_GREEN}
                    />
                  </Box>
                </Tooltip> */}
                <Button
                  variant="adjustant"
                  onClick={() => onOpenTestimonialsConfigMModal()}
                >
                  Update
                </Button>
              </HStack>
            )}
            <Heading as="h4" size="md" color="gray.500" mb={2}>
              Testimonials
            </Heading>

            {reviewedRosters
              .filter((r, i) => {
                const a =
                  profileUser.testimonialsConfig?.testimonialsToShow?.length

                //If the array contains testimonials it uses it to filter
                if (a) {
                  return profileUser.testimonialsConfig?.testimonialsToShow.includes(
                    r._id ?? ''
                  )
                } else {
                  // If not, take the last three
                  return i + 1 >= reviewedRosters.length - 2
                }
              })
              .map((roster) => (
                <Box key={roster._id} mb={2}>
                  <Text fontWeight={500} color="black" fontSize={20}>
                    “{roster.review?.testimonial}”
                  </Text>
                  <Text fontWeight={500} color="gray.500" fontSize={20}>
                    Issued {formatDate(roster.review?.date ?? '')}{' '}
                    {
                      firms.find((firm) => {
                        const firmId = jobsWhichWorkIsCompleted.find(
                          (j) => j._id === roster.jobId
                        )?.firmId
                        return firm._id === firmId
                      })?.firmName
                    }
                  </Text>
                </Box>
              ))}
          </Box>
        )}

      {/* Applications and invitations */}
      {profileUser?.userType === UserType.ADJUSTER &&
        showNonRedactedProfile && (
          <Box mb={2}>
            <Stack
              spacing={2}
              display="flex"
              direction={['column', 'column', 'row']}
            >
              <Stack
                width={['100%', '100%', '50%']}
                pt={8}
                pb={4}
                pl={8}
                pr={2}
                background="white"
                borderRadius={8}
                border="1px solid #ECEAE9"
                h={300}
              >
                <Heading as="h4" size="md" color="gray.500" mb={2}>
                  Job Applications
                </Heading>
                <TableContainer overflowY="auto">
                  <Table variant="simple" size="sm">
                    <Thead>
                      <Tr>
                        <Th>Job ID</Th>
                        <Th>Status</Th>
                        {/* <Th>Created</Th> */}
                      </Tr>
                    </Thead>
                    <Tbody>
                      {applications
                        //   .filter((a) => !jobs.find((j) => j._id === a.jobId))
                        .map((application) => (
                          <Tr key={application._id}>
                            <Td
                              width="80%"
                              maxW={0}
                              // maxW="auto"
                              overflow="hidden"
                              textOverflow="ellipsis"
                              whiteSpace="nowrap"
                            >
                              <Tooltip label={application.jobTitle}>
                                <Link
                                  onClick={() =>
                                    window
                                      .open(
                                        `${getPathRoute(profileUser)}${
                                          GLOBAL_ROUTES.JOB
                                        }/${application.jobId}`,
                                        '_blank'
                                      )
                                      ?.focus()
                                  }
                                >
                                  {application.jobTitle}
                                </Link>
                              </Tooltip>
                            </Td>
                            <Td maxW={0}>{application.status}</Td>
                          </Tr>
                        ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              </Stack>

              <Stack
                width={['100%', '100%', '50%']}
                pt={8}
                pb={4}
                pl={8}
                pr={2}
                background="white"
                borderRadius={8}
                border="1px solid #ECEAE9"
                h={300}
              >
                <Heading as="h4" size="md" color="gray.500" mb={2}>
                  Job Invitations
                </Heading>
                <TableContainer overflowY="auto">
                  <Table variant="simple" size="sm">
                    <Thead>
                      <Tr>
                        <Th>Job ID</Th>
                        <Th>Status</Th>
                        {/* <Th>Created</Th> */}
                      </Tr>
                    </Thead>
                    <Tbody>
                      {invitations
                        //   .filter((a) => !jobs.find((j) => j._id === a.jobId))
                        .map((invitation) => (
                          <Tr key={invitation._id}>
                            <Td>
                              <Link
                                onClick={() =>
                                  window
                                    .open(
                                      // `${
                                      //   getPathRoute(user)
                                      // }${GLOBAL_ROUTES.JOB}/${invitation.jobId}`,
                                      // '_blank'
                                      `${getPathRoute(profileUser)}${
                                        ADJUSTER_ROUTES.INVITATIONS
                                      }`,
                                      '_blank'
                                    )
                                    ?.focus()
                                }
                              >
                                <Tooltip label={invitation.jobTitle}>
                                  {invitation.jobTitle.length > 20
                                    ? invitation.jobTitle.substring(0, 20) +
                                      '...'
                                    : invitation.jobTitle}
                                </Tooltip>
                              </Link>
                            </Td>
                            <Td>{invitation.status}</Td>
                            {/* <Td>{(application as any).createdAt}</Td> */}
                          </Tr>
                        ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              </Stack>
            </Stack>
          </Box>
        )}

      {/* PREFERENCES AND AVAILABILITY */}
      {profileUser?.userType === UserType.ADJUSTER && (
        <Box mb={2}>
          <Stack
            spacing={2}
            display={['block', 'block', 'flex']}
            direction={['column', 'column', 'row']}
          >
            <Stack
              width={['100%', '100%', '50%']}
              mb={[2, 2, 0]}
              py={10}
              px={8}
              background="white"
              borderRadius={8}
              border="1px solid #ECEAE9"
            >
              <Heading as="h4" size="md" color="gray.500" mb={2}>
                Preferences
              </Heading>
              <Text as="b" size="xl">
                Job Locations
              </Text>
              <Box>
                {/* {preferredLocations.map((pl) => (
                  <Text as="p" color="gray.500" key={pl}>
                    {pl}
                  </Text>
                ))} */}
                <Text as="p" color="gray.500">
                  {preferredLocations.join(', ')}
                </Text>
              </Box>

              <Text as="b" size="xl">
                Job Types
              </Text>
              <Box>
                {profileUser?.jobTypes?.map((j) => (
                  <Text as="p" color="gray.500" key={j}>
                    {jobTypeOptions.find((o) => o.value === j)?.label}
                  </Text>
                ))}
              </Box>
              <Text as="p" color="gray.500">
                {!profileUser?.jobTypes || profileUser?.jobTypes?.length === 0
                  ? 'No preferred job types selected'
                  : ''}
              </Text>
            </Stack>
            <Stack
              width={['100%', '100%', '50%']}
              py={10}
              px={8}
              background="white"
              borderRadius={8}
              border="1px solid #ECEAE9"
            >
              <Heading
                as="h4"
                size="md"
                color="gray.500"
                mb={2}
                h="40px"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                Availability
                {canEditProfile && (
                  <Button
                    width="fit-content"
                    variant="adjustant"
                    isLoading={isUpdating}
                    onClick={updateAvailability}
                  >
                    Update
                  </Button>
                )}
              </Heading>

              {canEditProfile && status !== USER_STATUS.DEPLOYED ? (
                <>
                  <Label fontSize="sm">Select status</Label>
                  <CustomDropdown
                    label=""
                    //   placeholder="Select a state"
                    options={userStatusOptions.filter(
                      (o) => o.value !== USER_STATUS.DEPLOYED
                    )}
                    value={status}
                    textAlign="start"
                    handleChange={(status) => {
                      setStatus(status)
                    }}
                  />
                </>
              ) : (
                <Stack>
                  <Label fontSize="sm">Status</Label>
                  <Text
                    as="b"
                    color={
                      status === USER_STATUS.AVAILABLE
                        ? ADJUSTANT_GREEN
                        : 'gray.500'
                    }
                  >
                    {userStatusOptions.find((u) => u.value === status)?.label}
                  </Text>
                </Stack>
              )}

              {status === USER_STATUS.DEPLOYED && (
                <>
                  {canEditProfile ? (
                    <>
                      <Label fontSize="sm">Deployed until</Label>
                      <Input
                        type="date"
                        value={availabilityDate}
                        onChange={(e) => {
                          setAvailabilityDate(e.target.value)
                        }}
                      />
                      <Label fontSize="sm">Deployment info</Label>
                      <Textarea
                        resize="vertical"
                        size="xl"
                        p={2}
                        value={availabilityInfo}
                        borderRadius={3}
                        onChange={(e) => {
                          setAvailabilityInfo(e.target.value)
                        }}
                      />
                    </>
                  ) : (
                    <Stack>
                      <Label fontSize="sm">Deployed until</Label>
                      <Text as="b" color="gray.500">
                        {availabilityDate ? formatDate(availabilityDate) : '-'}
                      </Text>
                      <Label fontSize="sm">Deployment info</Label>
                      <Text as="b" color="gray.500">
                        {availabilityInfo || '-'}
                      </Text>
                    </Stack>
                  )}
                </>
              )}
            </Stack>
          </Stack>
        </Box>
      )}

      {/* Drivers License */}
      {profileUser?.userType === UserType.ADJUSTER &&
        showNonRedactedProfile && (
          <Box
            py={10}
            px={8}
            background="white"
            borderRadius={8}
            border="1px solid #ECEAE9"
            mb={2}
            ref={driversLicenseBoxRef}
          >
            <Heading as="h4" size="md" color="gray.500" mb={4} display="flex">
              {editorIsAdmin && profileUser?.driverLicense && (
                <Box
                  cursor="pointer"
                  mr={2}
                  display="flex"
                  alignItems="center"
                  color={getAdminDocumentColor(
                    profileUser?.driverLicense?.flag,
                    profileUser?.driverLicense?.approved
                  )}
                  onClick={() => {
                    setDocumentToFlag({
                      key: 'driverLicense',
                      index: 0,
                      flagReason: profileUser?.driverLicense?.flag,
                      approved: profileUser?.driverLicense?.approved,
                    })
                    onFlagDocumentModalOpen()
                  }}
                >
                  {getAdminDocumentIcon(
                    profileUser?.driverLicense?.flag,
                    profileUser?.driverLicense?.approved
                  )}
                </Box>
              )}
              {!editorIsAdmin && profileUser?.driverLicense?.flag && (
                <Tooltip
                  label={
                    getFlagReason(profileUser?.driverLicense?.flag)?.label ??
                    profileUser?.driverLicense?.flag
                  }
                  fontSize="md"
                  hasArrow
                  placement="end-start"
                >
                  <Box fontSize="xl" color="red" mr={2}>
                    <AiFillWarning />
                  </Box>
                </Tooltip>
              )}
              {!editorIsAdmin &&
                profileUser?.driverLicense?.approved &&
                ApprovedTooltip}
              {!editorIsAdmin &&
                !profileUser?.driverLicense?.flag &&
                !profileUser?.driverLicense?.approved &&
                profileUser?.driverLicense?.url &&
                PendingApprovalTooltip}
              Driver&apos;s License
            </Heading>
            <UpdateDriverLicense
              editable={false}
              id={profileUser?.driverLicense?.id ?? ''}
              address={profileUser?.driverLicense?.address ?? ''}
              city={profileUser?.driverLicense?.city ?? ''}
              state={profileUser?.driverLicense?.state ?? ''}
              zipcode={profileUser?.driverLicense?.zipcode ?? ''}
              birthDate={profileUser?.driverLicense?.birthDate ?? ''}
              url={profileUser?.driverLicense?.url ?? ''}
              fileName={getFileNameFromURL(profileUser?.driverLicense?.url)}
            />
          </Box>
        )}

      {/* Tax Forms */}
      {profileUser?.userType === UserType.ADJUSTER &&
        showNonRedactedProfile && (
          <Box
            py={10}
            px={8}
            background="white"
            borderRadius={8}
            border="1px solid #ECEAE9"
            mb={2}
          >
            <Heading as="h4" size="md" color="gray.500" mb={4} display="flex">
              Tax Forms
            </Heading>
            <Stack direction={['column', 'column', 'row']} spacing={4}>
              <Box ref={w9TaxFormBoxRef} width={['100%', '100%', '50%']}>
                <Heading
                  as="h4"
                  size="md"
                  color="gray.500"
                  mb={2}
                  display="flex"
                >
                  {editorIsAdmin && profileUser?.w9TaxForm && (
                    <Box
                      cursor="pointer"
                      mr={2}
                      display="flex"
                      alignItems="center"
                      color={getAdminDocumentColor(
                        profileUser?.w9TaxForm?.flag,
                        profileUser?.w9TaxForm?.approved
                      )}
                      onClick={() => {
                        setDocumentToFlag({
                          key: 'w9TaxForm',
                          index: 0,
                          flagReason: profileUser?.w9TaxForm?.flag,
                          approved: profileUser?.w9TaxForm?.approved,
                        })
                        onFlagDocumentModalOpen()
                      }}
                    >
                      {getAdminDocumentIcon(
                        profileUser?.w9TaxForm?.flag,
                        profileUser?.w9TaxForm?.approved
                      )}
                    </Box>
                  )}
                  {!editorIsAdmin && profileUser?.w9TaxForm?.flag && (
                    <Tooltip
                      label={
                        getFlagReason(profileUser?.w9TaxForm?.flag)?.label ??
                        profileUser?.w9TaxForm?.flag
                      }
                      fontSize="md"
                      hasArrow
                      placement="end-start"
                    >
                      <Box fontSize="xl" color="red" mr={2}>
                        <AiFillWarning />
                      </Box>
                    </Tooltip>
                  )}
                  {!editorIsAdmin &&
                    profileUser?.w9TaxForm?.approved &&
                    ApprovedTooltip}
                  {!editorIsAdmin &&
                    !profileUser?.w9TaxForm?.flag &&
                    !profileUser?.w9TaxForm?.approved &&
                    profileUser?.w9TaxForm?.url &&
                    PendingApprovalTooltip}
                  W-9 Form
                </Heading>
                <UpdateTaxForm
                  editable={false}
                  url={profileUser?.w9TaxForm?.url ?? ''}
                  fileName={getFileNameFromURL(profileUser?.w9TaxForm?.url)}
                  hideFile={
                    !!profileUser?.w9TaxForm?.approved && !editorIsAdmin
                  }
                  officialDocumentURL={W9_FORM_URL}
                  documentName="W-9 Form"
                />
              </Box>

              <Box ref={w4TaxFormBoxRef} width={['100%', '100%', '50%']}>
                <Heading
                  as="h4"
                  size="md"
                  color="gray.500"
                  mb={2}
                  display="flex"
                >
                  {editorIsAdmin && profileUser?.w4TaxForm && (
                    <Box
                      cursor="pointer"
                      mr={2}
                      display="flex"
                      alignItems="center"
                      color={getAdminDocumentColor(
                        profileUser?.w4TaxForm?.flag,
                        profileUser?.w4TaxForm?.approved
                      )}
                      onClick={() => {
                        setDocumentToFlag({
                          key: 'w4TaxForm',
                          index: 0,
                          flagReason: profileUser?.w4TaxForm?.flag,
                          approved: profileUser?.w4TaxForm?.approved,
                        })
                        onFlagDocumentModalOpen()
                      }}
                    >
                      {getAdminDocumentIcon(
                        profileUser?.w4TaxForm?.flag,
                        profileUser?.w4TaxForm?.approved
                      )}
                    </Box>
                  )}
                  {!editorIsAdmin && profileUser?.w4TaxForm?.flag && (
                    <Tooltip
                      label={
                        getFlagReason(profileUser?.w4TaxForm?.flag)?.label ??
                        profileUser?.w4TaxForm?.flag
                      }
                      fontSize="md"
                      hasArrow
                      placement="end-start"
                    >
                      <Box fontSize="xl" color="red" mr={2}>
                        <AiFillWarning />
                      </Box>
                    </Tooltip>
                  )}
                  {!editorIsAdmin &&
                    profileUser?.w4TaxForm?.approved &&
                    ApprovedTooltip}
                  {!editorIsAdmin &&
                    !profileUser?.w4TaxForm?.flag &&
                    !profileUser?.w4TaxForm?.approved &&
                    profileUser?.w4TaxForm?.url &&
                    PendingApprovalTooltip}
                  W-4 Form
                </Heading>
                <UpdateTaxForm
                  editable={false}
                  url={profileUser?.w4TaxForm?.url ?? ''}
                  fileName={getFileNameFromURL(profileUser?.w4TaxForm?.url)}
                  hideFile={
                    !!profileUser?.w4TaxForm?.approved && !editorIsAdmin
                  }
                  officialDocumentURL={W4_FORM_URL}
                  documentName="W-4 Form"
                />
              </Box>
            </Stack>
          </Box>
        )}

      {/* Employment Eligibility Forms */}
      {profileUser?.userType === UserType.ADJUSTER &&
        showNonRedactedProfile && (
          <Box
            py={10}
            px={8}
            background="white"
            borderRadius={8}
            border="1px solid #ECEAE9"
            mb={2}
          >
            <Heading as="h4" size="md" color="gray.500" mb={4} display="flex">
              Employment Eligibility Forms
            </Heading>

            <Stack direction={['column', 'column', 'row']} spacing={4}>
              {/* I9 Tax Form */}
              <Box ref={i9TaxFormBoxRef} width={['100%', '100%', '50%']}>
                <Heading
                  as="h4"
                  size="md"
                  color="gray.500"
                  mb={4}
                  display="flex"
                >
                  {editorIsAdmin && profileUser?.i9TaxForm && (
                    <Box
                      cursor="pointer"
                      mr={2}
                      display="flex"
                      alignItems="center"
                      color={getAdminDocumentColor(
                        profileUser?.i9TaxForm?.flag,
                        profileUser?.i9TaxForm?.approved
                      )}
                      onClick={() => {
                        setDocumentToFlag({
                          key: 'i9TaxForm',
                          index: 0,
                          flagReason: profileUser?.i9TaxForm?.flag,
                          approved: profileUser?.i9TaxForm?.approved,
                        })
                        onFlagDocumentModalOpen()
                      }}
                    >
                      {getAdminDocumentIcon(
                        profileUser?.i9TaxForm?.flag,
                        profileUser?.i9TaxForm?.approved
                      )}
                    </Box>
                  )}
                  {!editorIsAdmin && profileUser?.i9TaxForm?.flag && (
                    <Tooltip
                      label={
                        getFlagReason(profileUser?.i9TaxForm?.flag)?.label ??
                        profileUser?.i9TaxForm?.flag
                      }
                      fontSize="md"
                      hasArrow
                      placement="end-start"
                    >
                      <Box fontSize="xl" color="red" mr={2}>
                        <AiFillWarning />
                      </Box>
                    </Tooltip>
                  )}
                  {!editorIsAdmin &&
                    profileUser?.i9TaxForm?.approved &&
                    ApprovedTooltip}
                  {!editorIsAdmin &&
                    !profileUser?.i9TaxForm?.flag &&
                    !profileUser?.i9TaxForm?.approved &&
                    profileUser?.i9TaxForm?.url &&
                    PendingApprovalTooltip}
                  I-9 Employment Eligibility Form
                </Heading>
                <UpdateTaxForm
                  editable={false}
                  url={profileUser?.i9TaxForm?.url ?? ''}
                  fileName={getFileNameFromURL(profileUser?.i9TaxForm?.url)}
                  hideFile={
                    !!profileUser?.i9TaxForm?.approved && !editorIsAdmin
                  }
                  officialDocumentURL={I9_FORM_URL}
                  documentName="I-9 Form"
                />
              </Box>

              {/* Secondary Form of ID */}
              <Box
                ref={secondaryFormOfIDBoxRef}
                width={['100%', '100%', '50%']}
              >
                <Heading
                  as="h4"
                  size="md"
                  color="gray.500"
                  mb={4}
                  display="flex"
                >
                  {editorIsAdmin && profileUser?.secondaryFormOfID && (
                    <Box
                      cursor="pointer"
                      mr={2}
                      display="flex"
                      alignItems="center"
                      color={getAdminDocumentColor(
                        profileUser?.secondaryFormOfID?.flag,
                        profileUser?.secondaryFormOfID?.approved
                      )}
                      onClick={() => {
                        setDocumentToFlag({
                          key: 'secondaryFormOfID',
                          index: 0,
                          flagReason: profileUser?.secondaryFormOfID?.flag,
                          approved: profileUser?.secondaryFormOfID?.approved,
                        })
                        onFlagDocumentModalOpen()
                      }}
                    >
                      {getAdminDocumentIcon(
                        profileUser?.secondaryFormOfID?.flag,
                        profileUser?.secondaryFormOfID?.approved
                      )}
                    </Box>
                  )}
                  {!editorIsAdmin && profileUser?.secondaryFormOfID?.flag && (
                    <Tooltip
                      label={
                        getFlagReason(profileUser?.secondaryFormOfID?.flag)
                          ?.label ?? profileUser?.secondaryFormOfID?.flag
                      }
                      fontSize="md"
                      hasArrow
                      placement="end-start"
                    >
                      <Box fontSize="xl" color="red" mr={2}>
                        <AiFillWarning />
                      </Box>
                    </Tooltip>
                  )}
                  {!editorIsAdmin &&
                    profileUser?.secondaryFormOfID?.approved &&
                    ApprovedTooltip}
                  {!editorIsAdmin &&
                    !profileUser?.secondaryFormOfID?.flag &&
                    !profileUser?.secondaryFormOfID?.approved &&
                    profileUser?.secondaryFormOfID?.url &&
                    PendingApprovalTooltip}
                  Secondary Form of ID
                </Heading>
                <Box width="fit-content">
                  <UpdateTaxForm
                    editable={false}
                    url={profileUser?.secondaryFormOfID?.url ?? ''}
                    fileName={getFileNameFromURL(
                      profileUser?.secondaryFormOfID?.url
                    )}
                    hideFile={
                      !!profileUser?.secondaryFormOfID?.approved &&
                      !editorIsAdmin
                    }
                    documentName="Secondary form of ID"
                  />
                </Box>
              </Box>
            </Stack>
          </Box>
        )}

      {/* Miscellaneous Document */}
      {profileUser?.userType === UserType.ADJUSTER &&
        showNonRedactedProfile && (
          <Box
            py={10}
            px={8}
            background="white"
            borderRadius={8}
            border="1px solid #ECEAE9"
            mb={2}
            ref={miscellaneousDocumentBoxRef}
          >
            <Heading as="h4" size="md" color="gray.500" mb={4} display="flex">
              {editorIsAdmin && profileUser?.miscelaneosDocument && (
                <Box
                  cursor="pointer"
                  mr={2}
                  display="flex"
                  alignItems="center"
                  color={getAdminDocumentColor(
                    profileUser?.miscelaneosDocument?.flag,
                    profileUser?.miscelaneosDocument?.approved
                  )}
                  onClick={() => {
                    setDocumentToFlag({
                      key: 'miscelaneosDocument',
                      index: 0,
                      flagReason: profileUser?.miscelaneosDocument?.flag,
                      approved: profileUser?.miscelaneosDocument?.approved,
                    })
                    onFlagDocumentModalOpen()
                  }}
                >
                  {getAdminDocumentIcon(
                    profileUser?.miscelaneosDocument?.flag,
                    profileUser?.miscelaneosDocument?.approved
                  )}
                </Box>
              )}
              {!editorIsAdmin && profileUser?.miscelaneosDocument?.flag && (
                <Tooltip
                  label={
                    getFlagReason(profileUser?.miscelaneosDocument?.flag)
                      ?.label ?? profileUser?.miscelaneosDocument?.flag
                  }
                  fontSize="md"
                  hasArrow
                  placement="end-start"
                >
                  <Box fontSize="xl" color="red" mr={2}>
                    <AiFillWarning />
                  </Box>
                </Tooltip>
              )}
              {!editorIsAdmin &&
                profileUser?.miscelaneosDocument?.approved &&
                ApprovedTooltip}
              {!editorIsAdmin &&
                !profileUser?.miscelaneosDocument?.flag &&
                !profileUser?.miscelaneosDocument?.approved &&
                profileUser?.miscelaneosDocument?.url &&
                PendingApprovalTooltip}
              Miscellaneous Document
            </Heading>
            <Text as="i">Business and other related documents</Text>
            <UpdateMiscellaneosDocument
              editable={false}
              url={profileUser?.miscelaneosDocument?.url ?? ''}
              fileName={getFileNameFromURL(
                profileUser?.miscelaneosDocument?.url
              )}
              description={profileUser?.miscelaneosDocument?.description ?? ''}
              align="start"
            />
          </Box>
        )}

      {/* FEMA Certification */}
      {profileUser?.userType === UserType.ADJUSTER &&
        showNonRedactedProfile && (
          <Box
            py={10}
            px={8}
            background="white"
            borderRadius={8}
            border="1px solid #ECEAE9"
            mb={2}
            ref={stateLicenseAndFemaCertificationBoxRef}
          >
            {canEditProfile && (
              <HStack mb={2} justifyContent="end">
                <Button
                  variant="adjustant"
                  onClick={() =>
                    navigate(
                      `${editorIsAdmin ? GLOBAL_ROUTES.ADMIN : ''}${
                        ADJUSTER_ROUTES.UPDATE_LICENCES_AND_CERTIFICATIONS
                      }${editorIsAdmin ? '/' + profileUser?._id : ''}`
                    )
                  }
                >
                  Update
                </Button>
              </HStack>
            )}

            <Stack>
              <Heading as="h4" size="md" color="gray.500" mb={4} display="flex">
                {editorIsAdmin && profileUser.femaCertification?.has && (
                  <Box
                    mr={2}
                    display="flex"
                    alignItems="center"
                    color={getAdminDocumentColor(
                      profileUser?.femaCertification?.flag,
                      profileUser?.femaCertification?.approved
                    )}
                    onClick={() => {
                      setDocumentToFlag({
                        key: 'femaCertification',
                        index: 0,
                        flagReason: profileUser?.femaCertification?.flag,
                        approved: profileUser?.femaCertification?.approved,
                      })
                      onFlagDocumentModalOpen()
                    }}
                  >
                    {getAdminDocumentIcon(
                      profileUser?.femaCertification?.flag,
                      profileUser?.femaCertification?.approved
                    )}
                  </Box>
                )}
                {!editorIsAdmin &&
                  profileUser?.femaCertification?.has &&
                  profileUser?.femaCertification?.flag && (
                    <Tooltip
                      label={
                        getFlagReason(profileUser?.femaCertification?.flag)
                          ?.label ?? profileUser?.femaCertification?.flag
                      }
                      fontSize="md"
                      hasArrow
                      placement="end-start"
                    >
                      <Box fontSize="xl" color="red" mr={2}>
                        <AiFillWarning />
                      </Box>
                    </Tooltip>
                  )}
                {!editorIsAdmin &&
                  profileUser?.femaCertification?.has &&
                  profileUser?.femaCertification?.approved &&
                  ApprovedTooltip}
                {!editorIsAdmin &&
                  !profileUser?.femaCertification?.flag &&
                  !profileUser?.femaCertification?.approved &&
                  profileUser?.femaCertification?.has &&
                  PendingApprovalTooltip}
                FEMA Certification
              </Heading>
              <Stack width="100%" spacing={0}>
                <Text>
                  {profileUser?.femaCertification?.has ? 'Yes' : 'No'}
                </Text>
                {profileUser?.femaCertification?.has && (
                  <>
                    <Text>{profileUser?.femaCertification.fcnNumber}</Text>
                    <HStack>
                      {profileUser?.femaCertification?.categories?.map(
                        (category) => {
                          const label =
                            femaCategoriesOptions.find(
                              (f) => f.value === category
                            )?.label ?? ''
                          if (!label) return <></>
                          return (
                            <Tag
                              key={category}
                              color={ADJUSTANT_GREEN}
                              backgroundColor="gray.100"
                              size="sm"
                            >
                              {label}
                            </Tag>
                          )
                        }
                      )}
                    </HStack>
                    <HStack>
                      <Text color="gray.400" fontSize="sm">
                        Issued{' '}
                        {formatDate(
                          profileUser?.femaCertification?.issuedDate ?? ''
                        )}
                      </Text>
                      <Text
                        color={
                          dateIsExpired(
                            profileUser?.femaCertification?.expiryDate ?? ''
                          )
                            ? 'red'
                            : 'gray.400'
                        }
                        fontSize="sm"
                      >
                        {profileUser?.femaCertification?.expiryDate
                          ? `Expires ${formatDate(
                              profileUser?.femaCertification.expiryDate
                            )}`
                          : ''}
                      </Text>
                    </HStack>
                    <Link
                      color="teal.500"
                      href={encodeS3URI(profileUser?.femaCertification?.url)}
                      isExternal
                    >
                      {getFileNameFromURL(profileUser?.femaCertification?.url)}
                    </Link>
                  </>
                )}
              </Stack>
            </Stack>
          </Box>
        )}

      {/*  Licenses & Certifications */}
      {profileUser?.userType === UserType.ADJUSTER &&
        showNonRedactedProfile && (
          <Box
            py={10}
            px={8}
            background="white"
            borderRadius={8}
            border="1px solid #ECEAE9"
            mb={2}
          >
            {canEditProfile && (
              <HStack mb={2} justifyContent="end">
                <Button
                  variant="adjustant"
                  onClick={() =>
                    navigate(
                      `${editorIsAdmin ? GLOBAL_ROUTES.ADMIN : ''}${
                        ADJUSTER_ROUTES.DOCUMENT_CENTER
                      }`
                    )
                  }
                >
                  Sync with NIPR
                </Button>
                <Button
                  variant="adjustant"
                  onClick={() =>
                    navigate(
                      `${editorIsAdmin ? GLOBAL_ROUTES.ADMIN : ''}${
                        ADJUSTER_ROUTES.UPDATE_LICENCES_AND_CERTIFICATIONS
                      }${editorIsAdmin ? '/' + profileUser?._id : ''}`
                    )
                  }
                >
                  Update Manually
                </Button>
              </HStack>
            )}
            <Stack
              display={['block', 'block', 'flex']}
              direction={['column', 'column', 'row']}
            >
              <Stack width={['100%', '100%', '50%']} mb={[4, 4, 0]}>
                <Heading as="h4" size="md" color="gray.500" mb={2}>
                  Licenses & Certifications
                </Heading>

                {profileUser.npnNumber && (
                  <Heading as="h4" size="sm" color="gray.500" mb={2}>
                    NPN Number: {profileUser.npnNumber}
                  </Heading>
                )}

                <Box ref={licensesBoxRef}>
                  <Text as="b" size="xl">
                    Licenses
                  </Text>
                  {userSortedLicenses.map((license, li) => (
                    <Box key={`license-${li}`} mb={4}>
                      {/* Flag/approved icon and license name */}
                      <Box
                        display="flex"
                        cursor={canEditProfile ? 'pointer' : ''}
                      >
                        {editorIsAdmin && (
                          <Box
                            mr={2}
                            display="flex"
                            alignItems="center"
                            color={getAdminDocumentColor(
                              license?.flag,
                              license?.approved
                            )}
                            onClick={() => {
                              setDocumentToFlag({
                                key: 'licenses',
                                index: li,
                                flagReason: license.flag,
                                approved: license?.approved,
                              })
                              onFlagDocumentModalOpen()
                            }}
                          >
                            {getAdminDocumentIcon(
                              license?.flag,
                              license?.approved
                            )}
                          </Box>
                        )}
                        {!editorIsAdmin && license?.flag && (
                          <Tooltip
                            label={
                              getFlagReason(license?.flag)?.label ??
                              license?.flag
                            }
                            fontSize="md"
                            hasArrow
                            placement="end-start"
                          >
                            <Box fontSize="xl" color="red" mr={2}>
                              <AiFillWarning />
                            </Box>
                          </Tooltip>
                        )}
                        {!editorIsAdmin &&
                          !license?.flag &&
                          !license?.approved &&
                          license.url &&
                          PendingApprovalTooltip}
                        {!editorIsAdmin && license.approved && ApprovedTooltip}
                        <Text
                          onClick={() => {
                            if (canEditProfile) {
                              onOpenANiprPDFMModal()
                              setSelectedLicenseForPDF(license)
                            }
                          }}
                        >
                          {US_STATES.find(
                            (u) => u.abbreviation === license.state
                          )?.name ?? ''}{' '}
                          -{' '}
                          {license?.name ||
                            stateLicenses?.find((st) => st._id === license.id)
                              ?.name}
                        </Text>
                      </Box>

                      {(license.isPrimary ||
                        license.source === DATA_SOURCE.NIPR) && (
                        <HStack my={1}>
                          {license.isPrimary && (
                            <Tag
                              color={ADJUSTANT_GREEN}
                              backgroundColor="gray.100"
                              size="sm"
                            >
                              PRIMARY LICENSE
                            </Tag>
                          )}

                          {license.source === DATA_SOURCE.NIPR && (
                            <Tag
                              color={ADJUSTANT_GREEN}
                              backgroundColor="gray.100"
                              size="sm"
                            >
                              NIPR
                            </Tag>
                          )}
                        </HStack>
                      )}

                      {license?.loas && license.loas.length > 0 && (
                        <Box gap={2} display="block" my={1}>
                          {license?.loas?.map((loa, i) => (
                            <Tag
                              color={ADJUSTANT_GREEN}
                              backgroundColor="gray.100"
                              size="sm"
                              key={i}
                            >
                              {loas?.find((l) => l._id === loa)?.name}
                            </Tag>
                          )) ?? ''}
                        </Box>
                      )}

                      <HStack>
                        <Text color="gray.400" fontSize="sm">
                          Issued{' '}
                          {formatDate(
                            license.issuedDate,
                            {
                              weekday: undefined,
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric',
                            },
                            '---------------------------------------'
                          )}
                        </Text>
                        <Text
                          color={
                            dateIsExpired(license?.expiryDate ?? '')
                              ? 'red'
                              : 'gray.400'
                          }
                          fontSize="sm"
                        >
                          {license.expiryDate
                            ? `Expires ${formatDate(license.expiryDate)}`
                            : ''}
                        </Text>
                      </HStack>
                      {license?.url && (
                        <Link
                          color="teal.500"
                          href={encodeS3URI(license?.url)}
                          isExternal
                        >
                          {getFileNameFromURL(license?.url)}
                        </Link>
                      )}
                    </Box>
                  ))}
                  {(!profileUser?.licenses ||
                    profileUser?.licenses?.length === 0) && (
                    <Text>No licenses added</Text>
                  )}
                </Box>

                <Box ref={certificationsBoxRef}>
                  <Text as="b" size="xl">
                    Certifications
                  </Text>
                  {userSortedCertifications.map((certification, li) => (
                    <Box key={`certification-${li}`} mb={4}>
                      <Box display="flex" cursor="pointer">
                        {editorIsAdmin && (
                          <Box
                            mr={2}
                            display="flex"
                            alignItems="center"
                            color={getAdminDocumentColor(
                              certification?.flag,
                              certification?.approved
                            )}
                            onClick={() => {
                              setDocumentToFlag({
                                key: 'certifications',
                                index: li,
                                flagReason: certification.flag,
                                approved: certification?.approved,
                              })
                              onFlagDocumentModalOpen()
                            }}
                          >
                            {getAdminDocumentIcon(
                              certification?.flag,
                              certification?.approved
                            )}
                          </Box>
                        )}
                        {!editorIsAdmin && certification?.flag && (
                          <Tooltip
                            label={
                              getFlagReason(certification?.flag)?.label ??
                              certification?.flag
                            }
                            fontSize="md"
                            hasArrow
                            placement="end-start"
                          >
                            <Box fontSize="xl" color="red" mr={2}>
                              <AiFillWarning />
                            </Box>
                          </Tooltip>
                        )}
                        {!editorIsAdmin &&
                          certification.approved &&
                          ApprovedTooltip}
                        {!editorIsAdmin &&
                          !certification?.flag &&
                          !certification?.approved &&
                          certification.url &&
                          PendingApprovalTooltip}
                        <Text>
                          {US_STATES.find(
                            (u) => u.abbreviation === certification.state
                          )?.name ?? ''}{' '}
                          -{' '}
                          {certification.name ||
                            trainingCertifications?.find(
                              (tc) => tc._id === certification.id
                            )?.name}
                        </Text>
                      </Box>

                      {/* <Text color="gray.400" fontSize="sm">
                    {US_STATES.find(
                      (u) => u.abbreviation === certification.state
                    )?.name ?? ''}
                  </Text> */}

                      <HStack>
                        <Text color="gray.400" fontSize="sm">
                          Issued {formatDate(certification.issuedDate)}
                        </Text>
                        <Text
                          color={
                            dateIsExpired(certification?.expiryDate ?? '')
                              ? 'red'
                              : 'gray.400'
                          }
                          fontSize="sm"
                        >
                          {certification.expiryDate
                            ? ` Expires ${formatDate(certification.expiryDate)}`
                            : ''}
                        </Text>
                      </HStack>

                      {certification?.url && (
                        <Link
                          color="teal.500"
                          href={encodeS3URI(certification?.url)}
                          isExternal
                        >
                          {getFileNameFromURL(certification?.url)}
                        </Link>
                      )}
                    </Box>
                  ))}
                  {(!profileUser?.certifications ||
                    profileUser?.certifications?.length === 0) && (
                    <Text>No certifications added</Text>
                  )}
                </Box>
              </Stack>
              <Stack width={['100%', '100%', '50%']}>
                {profileUser?.userType === UserType.ADJUSTER && (
                  <Box width="100%">
                    <Tabs
                      variant="adjustant"
                      index={adjustersMapTabIndex}
                      onChange={(index) => setAdjustersMapTabIndex(index)}
                    >
                      <TabList>
                        <Tab fontWeight={500} tabIndex={1}>
                          LICENSES
                        </Tab>
                        <Tab fontWeight={500} tabIndex={2}>
                          CERTIFICATIONS
                        </Tab>
                      </TabList>
                    </Tabs>
                    <Box>
                      <Map
                        states={adjusterLicensesOrCertificationsStates}
                        editable={false}
                      />
                    </Box>
                  </Box>
                )}
              </Stack>
            </Stack>
          </Box>
        )}

      {/* Areas of Operation and Jobs locations */}
      {profileUser?.userType === UserType.FIRM && (
        <Box
          py={10}
          px={8}
          background="white"
          borderRadius={8}
          border="1px solid #ECEAE9"
          mb={2}
        >
          <Stack
            display={['block', 'block', 'flex']}
            direction={['column', 'column', 'row']}
          >
            <Stack width={['100%', '100%', '50%']}>
              <Heading
                as="h4"
                size="md"
                color="gray.500"
                mb={2}
                display="flex"
                justifyContent="space-between"
              >
                Areas of Operation
                {update && (
                  <Button
                    variant="adjustant"
                    onClick={updateAreasOfOperation}
                    isLoading={isUpdating}
                  >
                    Update
                  </Button>
                )}
              </Heading>
              <Map
                states={areasOfOperation}
                setStates={(a) => {
                  setUpdate(true)
                  setAreasOfOperation(a)
                }}
                editable={canEditProfile}
              />
            </Stack>
            <Stack width={['100%', '100%', '50%']}>
              <Heading
                as="h4"
                size="md"
                color="gray.500"
                mb={2}
                display="flex"
                justifyContent="space-between"
              >
                Jobs Locations
              </Heading>
              <Tabs
                variant="adjustant"
                index={firmsMapTabIndex}
                onChange={(index) => setFirmsMapTabIndex(index)}
              >
                <TabList>
                  <Tab fontWeight={500} tabIndex={1}>
                    OPEN JOBS
                  </Tab>
                  <Tab fontWeight={500} tabIndex={2}>
                    PAST JOBS
                  </Tab>
                </TabList>
              </Tabs>
              <Box>
                <Map states={firmJobsStates} editable={false} />
              </Box>
            </Stack>
          </Stack>
        </Box>
      )}

      {/* Jobs list */}
      {profileUser?.userType === UserType.FIRM && (
        <Box
          py={10}
          px={8}
          background="white"
          borderRadius={8}
          border="1px solid #ECEAE9"
          mb={2}
        >
          <Heading
            as="h4"
            size="md"
            color="gray.500"
            mb={2}
            display="flex"
            justifyContent="space-between"
          >
            Jobs
            <Button
              variant="adjustant"
              onClick={() =>
                navigate(
                  editorIsAdmin
                    ? `${GLOBAL_ROUTES.ADMIN}${FIRM_ROUTES.CREATE_JOB}`
                    : FIRM_ROUTES.CREATE_JOB
                )
              }
            >
              Create Job
            </Button>
          </Heading>
          <JobsList jobs={jobs} rosters={rosters} adjusters={adjusters} />
        </Box>
      )}

      {/* Licensing and Certification requirements */}
      {profileUser?.userType === UserType.FIRM && (
        <Box
          py={10}
          px={8}
          background="white"
          borderRadius={8}
          border="1px solid #ECEAE9"
          mb={2}
        >
          <Stack width="100%" mb={[4, 4, 0]}>
            <Heading
              as="h4"
              size="md"
              color="gray.500"
              mb={2}
              display="flex"
              justifyContent="space-between"
            >
              Preferred Certifications
              {canEditProfile && (
                <Button
                  variant="adjustant"
                  onClick={() => {
                    navigate(
                      `${editorIsAdmin ? GLOBAL_ROUTES.ADMIN : ''}${
                        FIRM_ROUTES.UPDATE_LICENCES_AND_CERTIFICATIONS_REQUIREMENTS
                      }${editorIsAdmin ? '/' + profileUser?._id : ''}`
                    )
                  }}
                >
                  Update
                </Button>
              )}
            </Heading>

            <Stack
              display={['block', 'block', 'flex']}
              direction={['column', 'column', 'row']}
            >
              <Stack width={['100%', '100%', '50%']}>
                {/* <Text as="b" size="xl">
                  Certifications
                </Text> */}
                {userSortedCertificationRquirements?.map(
                  (certification, ce) => (
                    <Box key={`certification-${ce}`} mb={1}>
                      {!certification?.isListed
                        ? certification.name
                        : trainingCertifications?.find(
                            (dl) => dl._id === certification.id
                          )?.name}
                    </Box>
                  )
                )}
                {(!profileUser?.additionalCertificationRequirements ||
                  profileUser?.additionalCertificationRequirements?.length ===
                    0) && <Text>No certifications added</Text>}
              </Stack>
            </Stack>
          </Stack>
        </Box>
      )}
    </Box>
  )
}

const Profile = () => {
  const { userId } = useParams()
  const { user, setUser }: UserContextType = useContext(UserContext)
  const [userToShow, setUserToShow] = useState<User | undefined>()
  const [canEditProfile, setCanEditProfile] = useState<boolean>(false)
  const [loadingProfile, setLoadingProfile] = useState<boolean>(true)

  const [getUser] = useLazyQuery(GET_USER_BY_ID, {
    onCompleted: (data: { UserOne: User }) => {
      setLoadingProfile(false)
      if (data?.UserOne) setUserToShow(data?.UserOne)
    },
    // onError: (err) => {}
  })

  useEffect(() => {
    if (!userToShow && user) {
      console.log('userId: ', userId)
      if (!userId || userId === user?._id) {
        setUserToShow(user)
        // profile's owners can edit their profile
        setCanEditProfile(true)
        setLoadingProfile(false)
      } else {
        // admin can edit any profile
        if (user.userType === UserType.ADMIN) setCanEditProfile(true)
        getUser({ variables: { id: userId }, fetchPolicy: 'network-only' })
      }
    }
  }, [userId, user, userToShow, setCanEditProfile, setUserToShow, getUser])

  if (loadingProfile)
    return (
      <Box
        minHeight="100%"
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Stack direction="row" spacing={4}>
          <Spinner size="xl" />
        </Stack>
      </Box>
    )
  return (
    <ProfileContent
      // this is the profiles user, not the user logged in
      viewer={user}
      profileUser={userToShow}
      setProfileUser={userToShow?._id === user?._id ? setUser : setUserToShow}
      canEditProfile={canEditProfile}
      editorIsAdmin={user?.userType === UserType.ADMIN}
    />
  )
}

export default Profile
