import {
  Box,
  Tabs,
  TabPanels,
  TabPanel,
  HStack,
  Spinner,
  Button,
  Text,
  useDisclosure,
  Tab,
  TabList,
  Heading,
  Stack,
} from '@chakra-ui/react'
import {} from 'framer-motion'
import {
  capitalizeFirst,
  checkIfAdjusterCanRequireWork,
} from '../../utils/functions'
import JobDetails from '../JobDetails/JobDetails'
import RequestWorkModal from '../Modals/RequestWorkModal'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { GLOBAL_ROUTES } from '../../App'
import {
  CREATE_APPLICATION,
  CREATE_NOTIFICATION,
  UPDATE_ROSTER_BY_ID,
} from '../../graphql/Mutations'
import {
  ADJUSTER_WORK_STATUS,
  Application,
  Job,
  JobApplicationStatus,
  JobSpecs,
  Roster,
} from '../../types/jobTypes'
import { showSuccessToast } from '../Toast/Toast'
import {
  GET_ROSTER_BY_ID,
  GET_JOB_SPECS_BY_ID,
  GET_USER_BY_ID,
} from '../../graphql/Queries'
import { User, UserType } from '../../types/userTypes'
import { ROSTER_DEPLOYMENT_SURVEY_QUESTIONS } from '../../pages/Firm/ReviewRoster/ReviewRoster'

const AdjusterJobTabs = ({
  job,
  user,
  backButton,
  userIsHired,
  applications,
  setApplications,
  admins,
}: {
  job: Job
  user: User
  backButton?: any
  userIsHired: boolean
  applications: Application[]
  setApplications: React.Dispatch<React.SetStateAction<Application[]>>
  admins: User[]
}) => {
  const {
    onOpen: onOpenApplicationModal,
    isOpen: isApplicationModalOpen,
    onClose: onCloseApplicationModal,
  } = useDisclosure()

  const [selectedTab, setSelectedTab] = useState<number>(0)
  const [showLoader, setShowLoader] = useState<boolean>(false)
  const [missingRequirementsMessage, setMissingRequirementsMessage] =
    useState<string>('')

  const [roster, setRoster] = useState<Roster | undefined>()
  const [firm, setFirm] = useState<User | undefined>()
  const [specs, setSpecs] = useState<JobSpecs | undefined>()

  const [updateRosterMutation] = useMutation(UPDATE_ROSTER_BY_ID)
  const [createApplicationMutation] = useMutation(CREATE_APPLICATION)
  const [createNotificationMutation] = useMutation(CREATE_NOTIFICATION)
  const [getRoster] = useLazyQuery(GET_ROSTER_BY_ID, {
    onCompleted: (data: { RosterOne: Roster }) => {
      if (data?.RosterOne) setRoster(data?.RosterOne)
    },
    onError: (err) => {},
  })

  const [getSpecs] = useLazyQuery(GET_JOB_SPECS_BY_ID, {
    onCompleted: (data: { GetJobSpecsById: JobSpecs }) => {
      if (data?.GetJobSpecsById) setSpecs(data?.GetJobSpecsById)
      setShowLoader(false)
    },
    onError: (err) => {
      setShowLoader(false)
    },
  })

  const [getFirm] = useLazyQuery(GET_USER_BY_ID, {
    onCompleted: (data: { UserOne: User }) => {
      if (data?.UserOne) setFirm(data?.UserOne)
    },
    onError: (err) => {},
  })

  useEffect(() => {
    if (job) {
      getFirm({ variables: { id: job.firmId } })
      getSpecs({ variables: { jobId: job?._id }, fetchPolicy: 'network-only' })
      getRoster({
        variables: {
          adjusterId: user?._id,
          jobId: job?._id,
        },
        fetchPolicy: 'network-only',
      })
    }
  }, [getFirm, getRoster, getSpecs, job, user?._id])

  const onRequestFeedback = useCallback(async () => {
    if (roster) {
      setShowLoader(true)
      const { _id, __typename, ...r } = roster as any
      const newRoster = {
        ...r,
        status: ADJUSTER_WORK_STATUS.FINISHED,
      }
      const result = await updateRosterMutation({
        variables: {
          roster: newRoster,
          id: _id,
        },
      })
      if (result?.data?.RosterUpdateOne?.record) {
        setRoster({
          ...newRoster,
          id: _id,
        })
        createNotificationMutation({
          variables: {
            notification: {
              userId: job?.firmId,
              message: `${user?.firstName} ${user?.lastName} has requested feedback for his work on job "${job?.title}".`,
              //   link: `job/${job?._id}`,
              link: `${GLOBAL_ROUTES.JOBS}/${job?._id}/1`,
              read: false,
            },
          },
        })
        showSuccessToast('Request Sent Succesfully!')
      }
      setShowLoader(false)
    }
  }, [
    createNotificationMutation,
    job?._id,
    job?.firmId,
    job?.title,
    roster,
    updateRosterMutation,
    user?.firstName,
    user?.lastName,
  ])

  const notifyAdmins = useCallback(
    async (message: string, jobId: string) => {
      const a = await admins.map(async (admin) => {
        await createNotificationMutation({
          variables: {
            notification: {
              userId: admin?._id,
              message,
              link: `${GLOBAL_ROUTES.ADMIN}${GLOBAL_ROUTES.JOB}/${jobId}`,
              read: false,
            },
          },
        })

        return null
      })

      await Promise.all(a)
    },
    [admins, createNotificationMutation]
  )

  const onRequestWork = useCallback(
    async (message: string) => {
      setShowLoader(true)
      const application = {
        message,
        adjusterId: user?._id,
        jobId: job?._id,
        status: JobApplicationStatus.WAITING,
      }
      const result = await createApplicationMutation({
        variables: { application },
      })
      if (result?.data?.ApplicationCreateOne?.record) {
        console.log(result?.data?.ApplicationCreateOne.record)
        setApplications([
          ...applications,
          result?.data?.ApplicationCreateOne.record,
        ])
        createNotificationMutation({
          variables: {
            notification: {
              userId: job?.firmId,
              message: `Name redacted - employee number ${user?._id} has requested to work for your job "${job?.title}".`,
              link: `${GLOBAL_ROUTES.JOBS}/${job?._id}/2`,
              read: false,
            },
          },
        })
        notifyAdmins(
          `${user?.firstName} ${user?.lastName} has requested to work on "${job?.title}"`,
          job?._id ?? ''
        )
        showSuccessToast('Application Sent Succesfully!')
      }
      setShowLoader(false)
    },
    [
      applications,
      createApplicationMutation,
      createNotificationMutation,
      job?._id,
      job?.firmId,
      job?.title,
      notifyAdmins,
      setApplications,
      user?._id,
      user?.firstName,
      user?.lastName,
    ]
  )

  const adjusterCanRequestWork = useMemo((): boolean => {
    if (job) {
      const { canRequireWork, message } = checkIfAdjusterCanRequireWork(
        job,
        specs,
        user
      )
      setMissingRequirementsMessage(message)
      return canRequireWork
    }
    return false
  }, [job, specs, user])

  const headerElement = useMemo(() => {
    if (user?.userType === UserType.ADMIN) return null

    const applicationStatus = applications.find(
      (a) => a.jobId === job?._id && a.adjusterId === user._id
    )?.status

    // If the adjuster is not working in the job
    if (!userIsHired) {
      // The adjuster hasn't applied for the job
      if (!applicationStatus) {
        if (adjusterCanRequestWork)
          return (
            <Button
              variant="adjustant"
              onClick={onOpenApplicationModal}
              size="sm"
            >
              Request Work
            </Button>
          )
        else
          return (
            <Text fontSize="sm" color="red" maxW="300px">
              {missingRequirementsMessage}
            </Text>
          )
      }

      if (applicationStatus === JobApplicationStatus.WAITING)
        return (
          <Text fontSize="sm" color="gray.500">
            Request Waiting
          </Text>
        )

      if (applicationStatus === JobApplicationStatus.REJECTED)
        return (
          <Text fontSize="sm" color="red">
            Request Rejected
          </Text>
        )
    } else {
      if (roster?.status === ADJUSTER_WORK_STATUS.ONGOING)
        return (
          <Button variant="adjustant" onClick={onRequestFeedback} size="sm">
            Request Feedback
          </Button>
        )

      if (roster?.status === ADJUSTER_WORK_STATUS.FINISHED) {
        return (
          <Text fontSize="sm" color="gray.500">
            {roster.review ? 'Feedback Available' : 'Feedback Requested'}
          </Text>
        )
      }
    }
  }, [
    userIsHired,
    user?.userType,
    user._id,
    applications,
    job?._id,
    adjusterCanRequestWork,
    onOpenApplicationModal,
    missingRequirementsMessage,
    roster?.status,
    roster?.review,
    onRequestFeedback,
  ])

  return (
    <>
      <RequestWorkModal
        isOpen={isApplicationModalOpen}
        onClose={(message, send) => {
          onCloseApplicationModal()
          if (send) onRequestWork(message)
        }}
      />

      {job && firm && !showLoader && (
        <Tabs
          variant="adjustant"
          h="100%"
          width="100%"
          index={selectedTab}
          onChange={(index) => {
            setSelectedTab(index)
          }}
        >
          {roster?.review && (
            <TabList
              background="white"
              width="100%"
              borderBottom="1px solid #e3e2e2"
            >
              <Tab
                fontWeight={500}
                width="50%"
                py={2}
                mx={0}
                fontSize={['sm', 'md']}
              >
                View Job Post
              </Tab>
              <Tab
                fontWeight={500}
                width="50%"
                py={2}
                mx={0}
                fontSize={['sm', 'md']}
              >
                View Feedback
              </Tab>
            </TabList>
          )}
          <TabPanels>
            <TabPanel>
              <Box p={[4, 8]} width="100%">
                {backButton}
                <JobDetails
                  specs={specs}
                  job={job}
                  firm={firm}
                  canUpdate={false}
                  headerElement={headerElement}
                />
              </Box>
            </TabPanel>
            {roster?.review && (
              <TabPanel>
                <Box p={[4, 8]} width="100%">
                  {ROSTER_DEPLOYMENT_SURVEY_QUESTIONS.map((element) => (
                    <Stack mb={4}>
                      <Heading fontSize="xl">{element.question}</Heading>
                      <Text fontSize="md" color="gray.500">
                        {capitalizeFirst(
                          roster.review?.survey[element.name] ?? ''
                        ).replace('_', ' ')}
                      </Text>
                    </Stack>
                  ))}

                  <Text as="i" fontSize={20}>
                    "{roster.review.testimonial}"
                  </Text>
                </Box>
              </TabPanel>
            )}
          </TabPanels>
        </Tabs>
      )}
      {showLoader && (
        <HStack direction="row" h="100%" justifyContent="center">
          <Spinner size="xl" />
        </HStack>
      )}
    </>
  )
}

export default AdjusterJobTabs
