export const emailTemplate = `
<html>
  <body>
    <div>
      <div style="display: flex; align-items: center; margin-bottom: 35px">
        <img
          src="https://adjustant-public-various.s3.amazonaws.com/logo.png"
          alt="Logo"
        />
      </div>

      <h3 style="margin-bottom: 35px">SUBJECT</h3>

      <div style="display: flex; margin-bottom: 30px">
        <div style="margin-right: 20px">
          <img
            src="SRC"
            alt="Profile Picture"
            style="border-radius: 50%"
            width="50px"
            heigth="50px"
          />
        </div>
        <div>
          <h4 style="margin-top: 0; margin-bottom: 5px">NAME</h4>
          <p style="margin-top: 0; margin-bottom: 12px">DATE</p>
          <h4 style="margin-top: 0; margin-bottom: 0">MESSAGE</h4>
        </div>
      </div>

      <a
        style="
          display: block;
          width: 115px;
          height: 25px;
          background: #488488;
          padding: 10px;
          text-align: center;
          border-radius: 5px;
          color: white;
          font-weight: bold;
          cursor: pointer;
          line-height: 26px;
          text-decoration: none;
        "
        cursor="pointer"
        href="SRCREPLY"
        >Reply</a
      >
    </div>
  </body>
</html>




`
