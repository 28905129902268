import {
  Box,
  Text,
  Flex,
  Stack,
  HStack,
  Spinner,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Button,
  useDisclosure,
} from '@chakra-ui/react'
import { BsPinAngleFill, BsChatTextFill, BsSearch } from 'react-icons/bs'
import { ChatGroupHeader } from './ChatGroupHeader'
import { ChatElement } from './ChatElement'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { UserContext } from '../../contexts/UserContext'
import { User, UserContextType, UserType } from '../../types/userTypes'
import {
  GET_ADMINS,
  GET_ADMIN_CHATS,
  GET_JOBS_BY_IDS,
  GET_USERS_BY_FILTERS,
  GET_USER_CHATS,
} from '../../graphql/Queries'
import { useLazyQuery, useMutation } from '@apollo/client'
import { CHAT_STATUS, Chat } from '../../types/chatTypes'
import ChatBox from './ChatBox'
import { Job } from '../../types/jobTypes'
import {
  CREATE_CHAT,
  MARK_MESSAGE_AS_READ,
  PULL_DELETED_BY_CHAT,
  PULL_PIN_CHAT,
  PUSH_DELETED_BY_CHAT,
  PUSH_MESSAGE,
  PUSH_PIN_CHAT,
} from '../../graphql/Mutations'
import useRecursiveTimeout from '../../hooks/useRecursiveTimeout'
import SendMessageModal from '../Modals/SendMessageModal'
import { showSuccessToast } from '../Toast/Toast'
import { sendUserEmailNotification } from '../../services/adjustant'
import { emailTemplate } from './emailTemplate'
import { formatDate } from '../../utils/functions'

const Chats = () => {
  const { user }: UserContextType = useContext(UserContext)
  const [showLoader, setShowLoader] = useState<boolean>(false)

  const [getUserChats] = useLazyQuery(GET_USER_CHATS)
  const [getAdminChats] = useLazyQuery(GET_ADMIN_CHATS)
  const [getUsersByFilter] = useLazyQuery(GET_USERS_BY_FILTERS)
  const [getChatsJobs] = useLazyQuery(GET_JOBS_BY_IDS)
  const [getAdmins] = useLazyQuery(GET_ADMINS)
  const [pushPinChatMutation] = useLazyQuery(PUSH_PIN_CHAT)
  const [pullPinChatMutation] = useLazyQuery(PULL_PIN_CHAT)
  const [pushDeletedByChatMutation] = useLazyQuery(PUSH_DELETED_BY_CHAT)
  const [pullDeletedByChatMutation] = useLazyQuery(PULL_DELETED_BY_CHAT)
  const [pushMessagetMutation] = useLazyQuery(PUSH_MESSAGE)
  const [markMessageAsReadMutation] = useLazyQuery(MARK_MESSAGE_AS_READ)
  const [createChatMutation] = useMutation(CREATE_CHAT)

  const [searchValue, setSearchValue] = useState('')
  const [dataRetrievedOnLoad, setDataRetrievedOnLoad] = useState(false)
  const [showLess, setShowLess] = useState(false)
  const [chats, setChats] = useState<Chat[]>([])
  const [chatUsers, setChatUsers] = useState<User[]>([])
  const [chatJobs, setChatJobs] = useState<Job[]>([])
  const [admins, setAdmins] = useState<User[]>([])
  const [selectedChat, setSelectedChat] = useState<Chat | undefined>()
  const [chatStartedNotificationSent, setChatStartedNotificationSent] =
    useState<boolean>(false)

  const {
    isOpen: startAdminChatModalIsOpen,
    onOpen: onOpenStartAdminChatModal,
    onClose: onCloseStartAdminChatModal,
  } = useDisclosure()

  const senderId = useMemo(() => {
    return user?.userType === UserType.ADMIN ? UserType.ADMIN : user?._id ?? ''
  }, [user?._id, user?.userType])

  const onSendMessage = useCallback(
    async (message: string, chat?: Chat) => {
      if (message && chat) {
        console.log('onSendMessage')
        console.log(message)
        const messageDate = new Date().toString()

        const newMessage = {
          id: `${Date.now()}`,
          sender: senderId,
          date: messageDate,
          message,
          read: false,
        }

        pushMessagetMutation({
          variables: {
            message: newMessage,
            id: chat._id,
          },
        })

        const receiverId =
          chat.users[0] === senderId ? chat.users[1] : chat.users[0]

        //Deletes the receiver from deletedBy so he can read the last message sent to him

        if (
          chat.deletedBy.includes(receiverId) &&
          receiverId !== UserType.ADMIN
        ) {
          pullDeletedByChatMutation({
            variables: {
              userId: receiverId,
              id: chat._id,
            },
          })
        }

        setChats((prevChats) => {
          return prevChats.map((prevChat) => {
            if (prevChat._id === chat._id) {
              setSelectedChat({
                ...prevChat,
                messages: [...prevChat.messages, newMessage],
              })
            }

            return {
              ...prevChat,
              messages:
                prevChat._id === chat._id
                  ? [...prevChat.messages, newMessage]
                  : [...prevChat.messages],
            }
          })
        })

        // Send email notification
        // if (!chatStartedNotificationSent) {
        if (1 === 1) {
          setChatStartedNotificationSent(true)

          const isAdminChat = chat.users.includes(UserType.ADMIN)
          const chatJobTitle =
            chatJobs.find((cj) => cj._id === chat.jobId)?.title ?? ''

          const destinationEmails: string[] =
            receiverId === UserType.ADMIN
              ? admins.map((a) => a.email)
              : [chatUsers.find((cu) => cu._id === receiverId)?.email ?? '']

          const notificationSubject = !isAdminChat
            ? `You have unread messages about the job ${chatJobTitle}`
            : senderId === UserType.ADMIN
            ? 'You have unread messages from the Adjustant Administrator'
            : `You have unread messages from the ${
                user?.userType === UserType.FIRM
                  ? 'firm ' + user.firmName
                  : 'adjuster ' + user?.firstName + ' ' + user?.lastName
              }`

          const senderFullName =
            user?.userType === UserType.ADJUSTER
              ? `${user.firstName} ${user.lastName}`
              : user?.userType === UserType.ADMIN
              ? 'Adjustant Administrator'
              : user?.firmName ?? ''

          const notificationHtml = emailTemplate
            .replace('SUBJECT', notificationSubject)
            .replace(
              'SRC',
              !!user?.profilePicture
                ? user?.profilePicture
                : 'https://adjustant-public-various.s3.amazonaws.com/no_profile_picture.png'
            )
            .replace('NAME', senderFullName)
            .replace(
              'DATE',
              formatDate(messageDate, {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
              })
            )
            .replace('MESSAGE', message)
            .replace('SRCREPLY', `${process.env.REACT_APP_APP_URL}/chats`)

          const p = {
            notificationHtml,
            notificationSubject,
            destinationEmails,
          }
          sendUserEmailNotification(p)
        }
      }
    },
    [
      admins,
      chatJobs,
      chatUsers,
      pullDeletedByChatMutation,
      pushMessagetMutation,
      senderId,
      user?.firmName,
      user?.firstName,
      user?.lastName,
      user?.profilePicture,
      user?.userType,
    ]
  )

  const onOpenChat = useCallback(
    async (chat: Chat) => {
      const unreadMessagesByMe = chat.messages.filter(
        (message) => !message.read && message.sender !== senderId
      )
      if (unreadMessagesByMe.length > 0) {
        const newReadMessages = unreadMessagesByMe.map((u) => ({
          ...u,
          read: true,
        }))

        markMessageAsReadMutation({
          variables: {
            messages: newReadMessages,
            chatId: chat._id,
          },
        })

        const newAllMessages = chat.messages.map((message) => ({
          ...message,
          read:
            !message.read && message.sender !== senderId ? true : message.read,
        }))

        setChats((prevChats) =>
          prevChats.map((prevChat) => {
            if (prevChat._id !== chat._id) return prevChat
            else {
              return {
                ...prevChat,
                messages: newAllMessages,
              }
            }
          })
        )
      }
    },
    [markMessageAsReadMutation, senderId]
  )

  const onDeleteChat = useCallback(
    async (chat: Chat) => {
      console.log('onDeleteChat')
      pushDeletedByChatMutation({
        variables: {
          userId: senderId,
          id: chat._id,
        },
      })

      setChats((prevChats) =>
        prevChats.filter((prevChat) => prevChat._id !== chat._id)
      )
    },
    [pushDeletedByChatMutation, senderId]
  )

  const onPinUnpinChat = useCallback(
    async (chat: Chat, pin: boolean) => {
      console.log('onPinUnpinChat')
      let newPinnedBy: string[] = []

      if (chat) {
        if (pin) {
          pushPinChatMutation({
            variables: {
              userId: senderId,
              id: chat._id,
            },
          })

          newPinnedBy = [...chat.pinnedBy, senderId]
        } else {
          pullPinChatMutation({
            variables: {
              userId: senderId,
              id: chat._id,
            },
          })
          newPinnedBy = [...chat.pinnedBy]
          const index = newPinnedBy.findIndex((id) => id === senderId)
          if (index >= 0) {
            newPinnedBy.splice(index, 1)
          }
        }

        //Updates only the pinnedBy of the chat pinned/unpinned
        setChats((prevChats) => {
          return prevChats.map((prevChat) => ({
            ...prevChat,
            pinnedBy:
              prevChat._id === chat._id ? newPinnedBy : prevChat.pinnedBy,
          }))
        })
      }
    },
    [pullPinChatMutation, pushPinChatMutation, senderId]
  )

  const sortChats = useCallback((unsortedChats: Chat[]) => {
    const sortedChats = unsortedChats.sort(function (a, b) {
      // Convert the date strings to Date objects
      const dateAString = a.messages[a.messages.length - 1].date
      const dateBString = b.messages[b.messages.length - 1].date

      const dateA = new Date(dateAString).getTime()
      const dateB = new Date(dateBString).getTime()

      // Subtract the dates to get a value that is either negative, positive, or zero
      return dateB - dateA
    })
    return sortedChats
  }, [])

  const pinnedChats = useMemo(() => {
    return sortChats(chats.filter((chat) => chat.pinnedBy.includes(senderId)))
  }, [chats, senderId, sortChats])

  const notPinnedChats = useMemo(() => {
    return sortChats(chats.filter((chat) => !chat.pinnedBy.includes(senderId)))
  }, [chats, senderId, sortChats])

  const searchResultChats = useMemo(() => {
    const c = chats.filter((chat) => {
      const receiverId =
        chat.users[0] === senderId ? chat.users[1] : chat.users[0]
      const receiver = chatUsers.find((chatUser) => chatUser._id === receiverId)

      const jobId = chat.jobId
      const job = chatJobs.find((chatJob) => chatJob._id === jobId)

      const regex = new RegExp(`.*${searchValue}.*`, 'i')

      if (receiverId === UserType.ADMIN && regex.test('admin')) return true

      if (
        receiver?.userType === UserType.FIRM &&
        regex.test(`${receiver.firmName}`)
      )
        return true

      if (
        receiver?.userType === UserType.ADJUSTER &&
        regex.test(`${receiver.firstName} ${receiver.lastName}`)
      )
        return true

      if (`${job?.title}`.search(regex) >= 0) return true

      return false
    })

    return sortChats(c)
  }, [chatJobs, chatUsers, chats, searchValue, senderId, sortChats])

  const selectedChatUser = useMemo(() => {
    const userId = selectedChat?.users?.find((userId) => userId !== senderId)
    return chatUsers.find((user) => user._id === userId)
  }, [chatUsers, selectedChat?.users, senderId])

  const firmCanMessageAdmin = useMemo(() => {
    return (
      user?.userType === UserType.FIRM &&
      !chats.find((chat) => chat.users.includes(UserType.ADMIN))
    )
  }, [chats, user?.userType])

  const getUser = useCallback(
    (id: string) => chatUsers.find((u) => u._id === id),
    [chatUsers]
  )

  const selectedChatJob = useMemo(() => {
    const jobId = selectedChat?.jobId
    return chatJobs.find((job) => job._id === jobId)
  }, [chatJobs, selectedChat?.jobId])

  const getJob = useCallback(
    (id: string) => chatJobs.find((u) => u._id === id),
    [chatJobs]
  )

  const startChatWithAdmin = useCallback(
    async (message: string) => {
      const newMessage = {
        id: `${Date.now()}`,
        message,
        date: new Date().toString(),
        sender: user?._id ?? '',
        read: false,
      }

      const newChat = {
        jobId: '',
        status: CHAT_STATUS.ACTIVE,
        users: [user?._id, UserType.ADMIN],
        deletedBy: [],
        messages: [newMessage],
        pinnedBy: [],
      }

      const result = await createChatMutation({
        variables: {
          chat: newChat,
        },
      })

      if (result?.data?.ChatCreateOne)
        setChats((prev) => [...prev, result?.data?.ChatCreateOne?.record])
      showSuccessToast('Message Sent!')
    },
    [createChatMutation, setChats, user?._id]
  )

  const getChatsRecursiveCallback = useCallback(async () => {
    // console.log('CALLING RECURSIVE')
    let result
    let rChats = []

    if (
      user?.userType &&
      [UserType.ADJUSTER, UserType.FIRM].includes(user?.userType)
    ) {
      result = await getUserChats({
        variables: { userId: senderId },
        fetchPolicy: 'network-only',
      })

      rChats = result?.data?.UserChats
    } else {
      result = await getAdminChats({
        fetchPolicy: 'network-only',
      })
      rChats = result?.data?.AdminChats
    }

    if (rChats?.length > 0) {
      const retrievedChats: Chat[] = rChats.map((c: any) => ({
        ...c,
      }))
      setChats((prevChats) => {
        const prevChatsIds = prevChats.map((p) => p._id)

        const newChatsFromRetrievedChats = retrievedChats.filter(
          (nrc) => !prevChatsIds.includes(nrc._id)
        )
        const noNewChatsFromRetrievedChats = retrievedChats.filter((nrc) =>
          prevChatsIds.includes(nrc._id)
        )

        //It keeps the local chat and append new messages only
        const noNewChatsFromRetrievedChatsParsed =
          noNewChatsFromRetrievedChats.map((retrievedChat) => {
            //I'll always find the prev chat
            const prevChat =
              prevChats.find((p) => p._id === retrievedChat._id) ??
              retrievedChat
            const uniqueMessages = [...(prevChat?.messages ?? [])]
            retrievedChat.messages.forEach((m) => {
              if (!uniqueMessages.find((u) => u.id === m.id)) {
                uniqueMessages.push(m)
              }
            })

            return {
              ...prevChat,
              messages: uniqueMessages,
            }
          })

        return [
          ...newChatsFromRetrievedChats,
          ...noNewChatsFromRetrievedChatsParsed,
        ]
      })

      //Updates selected chat
      if (selectedChat) {
        const nc = retrievedChats.find((nrc) => nrc._id === selectedChat._id)
        if (nc) setSelectedChat(nc)
      }

      //Retrieve chats jobs
      const retrievedChatsJobsIds = retrievedChats
        .map((c) => c.jobId)
        .filter((c) => c)
      //   console.log({ retrievedChatsJobsIds })
      const currentChatsJobsIds = chatJobs.map((c) => c._id ?? '')
      const thereAreNewJobsChats = !!retrievedChatsJobsIds.find(
        (retrievedJobId) => !currentChatsJobsIds.includes(retrievedJobId)
      )

      if (thereAreNewJobsChats) {
        // console.log('thereAreNewJobsChats')
        const result = await getChatsJobs({
          variables: { jobsIds: retrievedChatsJobsIds },
          fetchPolicy: 'network-only',
        })
        if (result?.data?.JobsByIds) {
          const jobs = result?.data?.JobsByIds
          setChatJobs([...jobs])
        }
      }

      //Retrieve chats users
      const retrievedChatsUsersIds = retrievedChats
        .map((c) => (c.users[0] === senderId ? c.users[1] : c.users[0]))
        .filter((f) => f !== UserType.ADMIN)

      //   console.log({ retrievedChatsUsersIds })
      const currentChatsUsersIds = chatUsers.map((c) => c._id ?? '')
      const thereAreNewUsersChats = !!retrievedChatsUsersIds.find(
        (retrievedChatId) => !currentChatsUsersIds.includes(retrievedChatId)
      )

      if (thereAreNewUsersChats) {
        // console.log('thereAreNewUsersChats')
        const result = await getUsersByFilter({
          variables: { ids: retrievedChatsUsersIds },
          fetchPolicy: 'network-only',
        })
        if (result?.data?.UsersByFilter) {
          const users = result?.data?.UsersByFilter
          setChatUsers([...users])
        }
      }
    }
  }, [
    user?.userType,
    getUserChats,
    senderId,
    getAdminChats,
    selectedChat,
    chatJobs,
    chatUsers,
    getChatsJobs,
    getUsersByFilter,
  ])

  const retrieveDataOnLoad = useCallback(async () => {
    console.log('retrieveDataOnLoad')
    setShowLoader(true)
    await getChatsRecursiveCallback()
    const result = await getAdmins()
    setAdmins(result?.data?.UserMany ?? [])
    setShowLoader(false)
  }, [getAdmins, getChatsRecursiveCallback])

  //It calls getChatsRecursiveCallback ON LOAD
  useEffect(() => {
    if (!dataRetrievedOnLoad) {
      retrieveDataOnLoad()
      setDataRetrievedOnLoad(true)
    }
  }, [dataRetrievedOnLoad, getChatsRecursiveCallback, retrieveDataOnLoad])

  //It starts to call getChatsRecursiveCallback AFTER 20000
  useRecursiveTimeout(getChatsRecursiveCallback, 20000)

  return (
    <Flex height="100%" overflow="hidden" background="white">
      <SendMessageModal
        isOpen={startAdminChatModalIsOpen}
        onClose={(message) => {
          onCloseStartAdminChatModal()
          if (message) startChatWithAdmin(message)
        }}
      />

      {!showLoader ? (
        <>
          <Stack spacing="4" width="320px" borderEndWidth="1px" pt="6">
            <Stack gap={0}>
              <Text
                as="i"
                fontSize="xm"
                color="black"
                fontWeight="500"
                px="5"
                noOfLines={showLess ? 1 : undefined}
              >
                The messaging portal is intended for use between employers and
                adjusters to relay deployment related information only. This
                feature is not intended to relay or store claim related or
                sensitive personal information. Messaging function is only
                available for employers and adjusters when an adjuster is on an
                active deployment. Employers may message the site admin
                regarding job or profile related questions at any time. Please
                visit us at{' '}
                <Link
                  color="#1b00fb"
                  onClick={() =>
                    window
                      .open(
                        `https://adjusttechllc.zendesk.com/hc/en-us`,
                        '_blank'
                      )
                      ?.focus()
                  }
                >
                  AdjustTech, LLC (zendesk.com)
                </Link>{' '}
                for any technical questions or assistance.
              </Text>
              <Link
                fontSize="xm"
                color="black"
                fontWeight="500"
                px="5"
                onClick={() => setShowLess(!showLess)}
              >
                Show {showLess ? 'more' : 'less'}
              </Link>
            </Stack>

            <Box px="5" display="flex" justifyContent="space-between">
              <Text fontSize="lg" fontWeight="medium">
                Messages
                {/* ({messages.length}) */}
              </Text>
              {firmCanMessageAdmin && (
                <Button
                  variant="adjustant"
                  size="sm"
                  onClick={() => {
                    onOpenStartAdminChatModal()
                  }}
                >
                  Message Admin
                </Button>
              )}
            </Box>

            <Box px="5">
              <InputGroup size="sm">
                <InputLeftElement color="fg.subtle">
                  <BsSearch />
                </InputLeftElement>
                <Input
                  placeholder="Search chats..."
                  value={searchValue}
                  onChange={(e) => {
                    setSearchValue(e.target.value)
                  }}
                />
              </InputGroup>
            </Box>

            {/* Chats list */}
            <Stack mt="2" spacing="4" flex="1" overflowY="auto" px="5" pb="5">
              {!searchValue ? (
                <>
                  {/* Pinned Chats */}
                  {pinnedChats.length > 0 && (
                    <Stack mt="2" spacing="4">
                      <ChatGroupHeader icon={BsPinAngleFill}>
                        Pinned
                      </ChatGroupHeader>
                      <Stack spacing="1" mx="-4">
                        {pinnedChats.map((chat) => (
                          <Box
                            key={`${chat._id}-${JSON.stringify(chat.messages)}`}
                            cursor="pointer"
                            onClick={() => {
                              setSelectedChat(chat)
                              onOpenChat(chat)
                            }}
                          >
                            <ChatElement
                              chat={chat}
                              receiver={getUser(
                                chat.users.find((id) => id !== senderId) ?? ''
                              )}
                              job={getJob(chat.jobId)}
                              isSelected={selectedChat?._id === chat._id}
                              onPinUnpinChat={onPinUnpinChat}
                              onDeleteChat={onDeleteChat}
                            />
                          </Box>
                        ))}
                      </Stack>
                    </Stack>
                  )}

                  {/* Not pinned chats */}
                  <Stack mt="2" spacing="4">
                    <ChatGroupHeader icon={BsChatTextFill}>
                      Messages
                    </ChatGroupHeader>
                    <Stack spacing="1" mx="-4">
                      {notPinnedChats.map((chat) => (
                        <Box
                          key={`${chat._id}-${JSON.stringify(chat.messages)}`}
                          cursor="pointer"
                          onClick={() => {
                            setSelectedChat(chat)
                            onOpenChat(chat)
                          }}
                        >
                          <ChatElement
                            chat={chat}
                            receiver={getUser(
                              chat.users.find((id) => id !== senderId) ?? ''
                            )}
                            job={getJob(chat.jobId)}
                            isSelected={selectedChat?._id === chat._id}
                            onPinUnpinChat={onPinUnpinChat}
                            onDeleteChat={onDeleteChat}
                          />
                        </Box>
                      ))}
                    </Stack>
                  </Stack>
                </>
              ) : (
                <>
                  {/* serach result chats */}
                  <Stack mt="2" spacing="4">
                    <ChatGroupHeader icon={BsChatTextFill}>
                      Results
                    </ChatGroupHeader>
                    <Stack spacing="1" mx="-4">
                      {searchResultChats.map((chat) => (
                        <Box
                          key={`${chat._id}-${JSON.stringify(chat.messages)}`}
                          cursor="pointer"
                          onClick={() => {
                            setSelectedChat(chat)
                            onOpenChat(chat)
                          }}
                        >
                          <ChatElement
                            chat={chat}
                            receiver={getUser(
                              chat.users.find((id) => id !== senderId) ?? ''
                            )}
                            job={getJob(chat.jobId)}
                            isSelected={selectedChat?._id === chat._id}
                            onPinUnpinChat={onPinUnpinChat}
                            onDeleteChat={onDeleteChat}
                          />
                        </Box>
                      ))}
                    </Stack>
                  </Stack>
                </>
              )}
            </Stack>
          </Stack>

          {/* Chat */}
          {selectedChat && (
            <ChatBox
              chat={selectedChat}
              receiver={selectedChatUser}
              job={selectedChatJob}
              onSendMessage={onSendMessage}
              onClick={() => {
                onOpenChat(selectedChat)
              }}
            />
          )}
        </>
      ) : (
        <HStack direction="row" h="100%" w="100%" justifyContent="center">
          <Spinner size="xl" />
        </HStack>
      )}
    </Flex>
  )
}
export default Chats
