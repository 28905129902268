import {
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  HStack,
  Heading,
  Divider,
  Button,
  Box,
  Text,
  useDisclosure,
  Spinner,
  Tag,
} from '@chakra-ui/react'
import { ADJUSTANT_GREEN } from '../../themes/themes'
import {
  ADJUSTER_WORK_STATUS,
  Application,
  Invitation,
  Job,
  JobApplicationStatus,
  JobSpecs,
  JobStatus,
  RequiredTypes,
  Roster,
} from '../../types/jobTypes'
import AdjusterCard from '../AdjusterCard/AdjusterCard'
import AdjusterSearcher, {
  OnSearchFunctionParams,
} from '../AdjusterSearcher/AdjusterSearcher'
import RosterComponent from '../Roster/Roster'
import JobDetails from '../JobDetails/JobDetails'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import {
  USER_STATUS,
  User,
  UserContextType,
  UserType,
} from '../../types/userTypes'
import { useLazyQuery, useMutation } from '@apollo/client'
import { ADJUSTER_ROUTES, GLOBAL_ROUTES } from '../../App'
import { StateLicesesContext } from '../../contexts/StateLicesesContext'
import {
  UPDATE_APPLICATION_BY_ID,
  CREATE_ROSTER,
  CREATE_INVITATION,
  UPDATE_JOB_BY_ID,
  CREATE_NOTIFICATION,
  DELETE_APPLICATION_BY_ID,
  DELETE_INVITATION_BY_ID,
  DELETE_APPLICATIONS_BY_ID,
  UPDATE_USER_BY_ID,
  UPDATE_INVITATION_BY_ID,
} from '../../graphql/Mutations'
import {
  GET_ROSTERS,
  GET_APPLICATIONS,
  GET_INVITATIONS,
  GET_USERS_BY_FILTERS,
  GET_JOB_SPECS_BY_ID,
  GET_ADMINS,
  GET_FIRM_CHATS,
} from '../../graphql/Queries'
import {
  StateLicenseContextType,
  US_STATES,
} from '../../types/stateLicensesTypes'
import {
  checkIfAdjusterMeetsTheRequirements,
  isNumeric,
  storeFile,
} from '../../utils/functions'
import { showSuccessToast } from '../Toast/Toast'
import InviteAdjusterModal from '../Modals/InviteAdjusterModal'
import { UserContext } from '../../contexts/UserContext'
import { Chat } from '../../types/chatTypes'

export interface ApplicationUser extends User {
  meetRequirements: boolean
  requirementsMessage: string
}

function FirmJobTabs({
  firm,
  job,
  setJob,
  backButton,
  jobId,
  tab,
  setShowParentLoader,
  onDelete,
  onUpdate,
  onReadQueryParams,
}: {
  firm?: User
  job: Job
  setJob: React.Dispatch<React.SetStateAction<Job | undefined>>
  backButton?: any
  jobId?: string
  tab?: string
  setShowParentLoader?: React.Dispatch<React.SetStateAction<boolean>>
  onDelete?: (id?: string) => void
  onUpdate?: (updatedJob?: Job) => void
  onReadQueryParams?: () => void
}) {
  const { stateLicenses }: StateLicenseContextType =
    useContext(StateLicesesContext)
  const {
    onOpen: OnInviteModalOpen,
    isOpen: isInviteModalOpen,
    onClose: onInviteModalClose,
  } = useDisclosure()

  const { user }: UserContextType = useContext(UserContext)
  const [selectedTab, setSelectedTab] = useState<number>(0)
  const [showLoader, setShowLoader] = useState<boolean>(false)
  const [showFilterLoader, setShowFilterLoader] = useState<boolean>(false)
  const [admins, setAdmins] = useState<User[]>([])
  const [adjusterToInviteRequested, setAdjusterToInviteRequested] =
    useState<boolean>(false)
  const [specs, setSpecs] = useState<JobSpecs | undefined>()
  const [rosters, setRosters] = useState<Roster[]>([])
  const [rostersUsers, setRostersUsers] = useState<User[]>([])
  const [chats, setChats] = useState<Chat[]>([])
  const [applications, setApplications] = useState<Application[]>([])
  const [applicationsUsers, setApplicationsUsers] = useState<ApplicationUser[]>(
    []
  )
  const [invitations, setInvitations] = useState<Invitation[]>([])

  const canUpdate = useMemo(() => {
    return job?.status !== JobStatus.FINISHED
  }, [job?.status])

  const canSetAsFinished = useMemo(() => {
    return canUpdate && !rosters.find((r) => !r.review)
  }, [canUpdate, rosters])

  const canDelete = useMemo(() => {
    return rosters.length === 0
  }, [rosters])

  const [adjustersToInvite, setAdjustersToInvite] = useState<User[]>([])
  const [adjusterToInvite, setAdjusterToInvite] = useState<User | undefined>()

  const [getRosters] = useLazyQuery(GET_ROSTERS)
  const [getChats] = useLazyQuery(GET_FIRM_CHATS)
  const [getApplications] = useLazyQuery(GET_APPLICATIONS)
  const [getInvitations] = useLazyQuery(GET_INVITATIONS)
  const [getUsersByFilter] = useLazyQuery(GET_USERS_BY_FILTERS)
  const [updateInvitationMutation] = useMutation(UPDATE_INVITATION_BY_ID)
  const [updateApplicationMutation] = useMutation(UPDATE_APPLICATION_BY_ID)
  const [updateAdjuster] = useMutation(UPDATE_USER_BY_ID)
  const [createRosterMutation] = useMutation(CREATE_ROSTER)
  const [createInvitationMutation] = useMutation(CREATE_INVITATION)
  const [updateJobMutation] = useMutation(UPDATE_JOB_BY_ID)
  const [createNotificationMutation] = useMutation(CREATE_NOTIFICATION)
  const [deleteApplicationMutation] = useMutation(DELETE_APPLICATION_BY_ID)
  const [deleteInvitationMutation] = useMutation(DELETE_INVITATION_BY_ID)
  const [deleteApplicationsMutation] = useMutation(DELETE_APPLICATIONS_BY_ID)

  const [getAdmins] = useLazyQuery(GET_ADMINS, {
    onCompleted: (data: { UserMany: User[] }) => {
      if (data?.UserMany) {
        const newUsers = [...data?.UserMany]
        setAdmins(newUsers)
      }
    },
    onError: (err) => {
      console.log(err)
    },
  })

  const [getJobSpecs] = useLazyQuery(GET_JOB_SPECS_BY_ID, {
    onCompleted: (data: { GetJobSpecsById: JobSpecs }) => {
      if (data?.GetJobSpecsById) setSpecs(data?.GetJobSpecsById)
    },
    onError: (err) => {
      setShowLoader(false)
    },
  })

  //   const allUserLicensesStates = useMemo((): string[] => {
  //     let states: string[] = []

  //     user?.licenses?.forEach((license) => {
  //       const listedLicenseStates = stateLicenses?.find(
  //         (l) => license.id === l._id
  //       )?.states
  //       if (listedLicenseStates) {
  //         states = mergeStringArrays(states, Object.keys(listedLicenseStates))
  //       }
  //     })

  //     return states
  //   }, [stateLicenses, user?.licenses])

  const mapAndSortUsers = useCallback(
    (users: User[], selectedJob: Job): ApplicationUser[] => {
      const selectedJobLicenses = selectedJob.licensingRequirements.filter(
        (l) => l.type === RequiredTypes.NEEDED
      )
      const selectedJobCertifications =
        selectedJob.certificationRequirements?.filter(
          (c) => c.isListed && c.type === RequiredTypes.NEEDED
        )

      const customUsers: ApplicationUser[] = users
        .map((user) => {
          const { meetRequirements, requirementsMessage } =
            checkIfAdjusterMeetsTheRequirements({
              adjuster: user,
              jobLicenses: selectedJobLicenses,
              jobCertifications: selectedJobCertifications,
              stateLicenses: stateLicenses ?? [],
              allUserLicensesStates: [],
            })

          return {
            meetRequirements,
            requirementsMessage,
            ...user,
          }
        })
        .sort((x, y) => {
          // true values first
          return x.meetRequirements === y.meetRequirements ? 0 : x ? -1 : 1
        })

      return customUsers
    },
    [stateLicenses]
  )

  const retrieveSelectedJobData = useCallback(
    async (job: Job) => {
      setShowLoader(true)
      setJob(job)
      setRosters([])
      setRostersUsers([])
      setApplications([])
      setApplicationsUsers([])
      setAdjustersToInvite([])
      setAdjusterToInviteRequested(false)
      setInvitations([])
      let result
      let adjustersIds: string[] = []

      // GET ROSTERS
      result = await getRosters({
        variables: { jobId: job._id },
        fetchPolicy: 'network-only',
      })
      if (result?.data?.RosterMany?.length > 0) {
        const ros: Roster[] = result?.data?.RosterMany

        setRosters([...ros])
        adjustersIds = ros.map((a) => a.adjusterId)
        result = await getUsersByFilter({
          variables: { ids: adjustersIds, userType: UserType.ADJUSTER },
        })
        if (result?.data?.UsersByFilter)
          setRostersUsers([...result?.data?.UsersByFilter])
      }

      //GET CHATS
      if (adjustersIds.length > 0) {
        result = await getChats({
          variables: { jobId: job._id, usersIds: adjustersIds },
          fetchPolicy: 'network-only',
        })
        if (result?.data?.FirmChats) {
          setChats([...result?.data?.FirmChats.map((c: any) => ({ ...c }))])
        }
      }

      // GET APPLICATIONS
      result = await getApplications({
        variables: { jobId: job._id, status: JobApplicationStatus.WAITING },
        fetchPolicy: 'network-only',
      })
      if (result?.data?.ApplicationMany?.length > 0) {
        const apps: Application[] = result?.data?.ApplicationMany
        setApplications([...apps.map((a) => ({ ...a }))]) //to delete readonly
        const ids = apps.map((a) => a.adjusterId)
        result = await getUsersByFilter({
          variables: { ids, userType: UserType.ADJUSTER },
        })
        if (result?.data?.UsersByFilter) {
          const users = mapAndSortUsers(
            result?.data?.UsersByFilter as User[],
            job
          )
          setApplicationsUsers([...users])
        }
      }

      // GET INVITATIONS getInvitations
      result = await getInvitations({
        variables: { jobId: job._id, status: JobApplicationStatus.WAITING },
        fetchPolicy: 'network-only',
      })
      if (result?.data?.InvitationMany?.length > 0) {
        const invs: Invitation[] = result?.data?.InvitationMany
        setInvitations([...invs.map((a) => ({ ...a }))]) //to delete readonly
      }

      setShowLoader(false)
    },
    [
      getApplications,
      getChats,
      getInvitations,
      getRosters,
      getUsersByFilter,
      mapAndSortUsers,
      setJob,
    ]
  )

  useEffect(() => {
    if (job) {
      //   console.log('REFRESHINGGGGGG JOB')
      retrieveSelectedJobData(job)
      getJobSpecs({
        variables: { jobId: job?._id },
        fetchPolicy: 'network-only',
      })
      getAdmins()
      //   if (job.status === JobStatus.FINISHED) setSelectedTab(1)
      if (tab && isNumeric(+tab)) {
        const nTab = +tab
        if (job.status === JobStatus.FINISHED) {
          if (nTab >= 0 && nTab < 2) setSelectedTab(nTab)
          else setSelectedTab(0)
        } else {
          if (nTab >= 0 && nTab < 3) setSelectedTab(nTab)
          else setSelectedTab(0)
        }
        if (onReadQueryParams) onReadQueryParams()
      } else {
        setSelectedTab(0)
      }
    }
  }, [
    getAdmins,
    getJobSpecs,
    job,
    onReadQueryParams,
    retrieveSelectedJobData,
    tab,
  ])

  const deleteApplicationAdjusterInvitation = useCallback(
    async (adjusterId?: string) => {
      const invitationIndex = invitations.findIndex(
        (i) => i.adjusterId === adjusterId
      )
      if (invitationIndex >= 0) {
        const result = await deleteInvitationMutation({
          variables: {
            id: invitations[invitationIndex]?._id,
          },
        })

        if (result?.data?.InvitationRemoveById?.record) {
          invitations.splice(invitationIndex, 1)
          setInvitations([...invitations])
        }
      }
    },
    [deleteInvitationMutation, invitations]
  )

  const notifyAdmins = useCallback(
    async (message: string, jobId: string) => {
      const a = await admins.map(async (admin) => {
        await createNotificationMutation({
          variables: {
            notification: {
              userId: admin?._id,
              message,
              link: `${GLOBAL_ROUTES.ADMIN}${GLOBAL_ROUTES.JOB}/${jobId}`,
              read: false,
            },
          },
        })

        return null
      })

      await Promise.all(a)
    },
    [admins, createNotificationMutation]
  )

  const rejectAllInvites = useCallback(async () => {
    //REJECT ALL PENDING INVITATIONS
    const invitationsResult = await invitations.map(async (invitation) => {
      await updateInvitationMutation({
        variables: {
          invitation: { status: JobApplicationStatus.REJECTED },
          id: invitation?._id,
        },
      })
    })
    await Promise.all(invitationsResult)
    setInvitations([])

    //REJECT ALL PENDING APPLICATIONS
    // const applicationsResult = await applications.map(async (application) => {
    //   await updateApplicationMutation({
    //     variables: {
    //       application: { status: JobApplicationStatus.REJECTED },
    //       id: application?._id,
    //     },
    //   })
    // })
    // await Promise.all(applicationsResult)
    // setApplications([])
    // setApplicationsUsers([])
  }, [invitations, updateInvitationMutation])

  const acceptOrRejectApplication = useCallback(
    async (accept: boolean, adjuster?: User) => {
      if (adjuster) {
        setShowLoader(true)
        const application = {
          status: accept
            ? JobApplicationStatus.ACCEPTED
            : JobApplicationStatus.REJECTED,
        }
        const applicationIndex = applications.findIndex(
          (a) => a.adjusterId === adjuster._id
        )
        const applicationUserIndex = applicationsUsers.findIndex(
          (a) => a._id === adjuster._id
        )

        // Update application
        await updateApplicationMutation({
          variables: {
            application,
            id: applications[applicationIndex]?._id,
          },
        })

        applications.splice(applicationIndex, 1)
        setApplications([...applications])

        applicationsUsers.splice(applicationUserIndex, 1)
        setApplicationsUsers([...applicationsUsers])

        if (accept) {
          //CREATE ROSTER
          const roster = {
            jobId: job?._id,
            adjusterId: adjuster?._id,
            status: ADJUSTER_WORK_STATUS.ONGOING,
          }
          const result = await createRosterMutation({ variables: { roster } })
          if (result?.data?.RosterCreateOne) {
            setRosters((prev) => [...prev, result?.data?.RosterCreateOne])
            setRostersUsers((prev) => [
              ...prev,
              { ...adjuster, status: USER_STATUS.DEPLOYED },
            ])

            //CHANGE ADJUSTER STATUS
            await updateAdjuster({
              variables: {
                user: { status: USER_STATUS.DEPLOYED },
                id: adjuster._id,
              },
            })

            //SEND NOTIFICATION TO THE ADJUSTER
            createNotificationMutation({
              variables: {
                notification: {
                  userId: adjuster?._id,
                  message: `You’ve been accepted for job "${job?.title}". The employer will reach out to you shortly.`,
                  link: `${GLOBAL_ROUTES.JOBS}/${job?._id}/1`,
                  read: false,
                },
              },
            })

            //IF ROSTER IS FULL
            if (rosters.length + 1 === job?.adjustersNeeded) {
              notifyAdmins(
                `The roster of the job "${job?.title}" has been filled`,
                job?._id ?? ''
              )

              //REJECT ALL PENDING INVITATIONS
              await rejectAllInvites()
            }
          }
        }

        if (specs)
          setSpecs({
            ...specs,
            hired: accept ? specs.hired + 1 : specs.hired,
            applications: specs.applications - 1,
          })

        createNotificationMutation({
          variables: {
            notification: {
              userId: adjuster?._id,
              message: `Your application for the job "${job?.title}" has been ${
                accept ? 'accepted' : 'rejected'
              }.`,
              link: `${GLOBAL_ROUTES.JOB}/${job?._id}`,
              read: false,
            },
          },
        })
        deleteApplicationAdjusterInvitation(adjuster._id)
        showSuccessToast(
          `Application ${accept ? 'accepted' : 'rejected'} Succesfully!`
        )
        setShowLoader(false)
      }
    },
    [
      applications,
      applicationsUsers,
      updateApplicationMutation,
      specs,
      createNotificationMutation,
      job?.title,
      job?._id,
      job?.adjustersNeeded,
      deleteApplicationAdjusterInvitation,
      createRosterMutation,
      updateAdjuster,
      rosters.length,
      notifyAdmins,
      rejectAllInvites,
    ]
  )

  const deleteInvitedAdjusterApplication = useCallback(async () => {
    const applicationIndex = applications.findIndex(
      (a) => a.adjusterId === adjusterToInvite?._id
    )
    if (applicationIndex >= 0) {
      const result = await deleteApplicationMutation({
        variables: {
          id: applications[applicationIndex]?._id,
        },
      })

      if (result?.data?.ApplicationRemoveById?.record) {
        applications.splice(applicationIndex, 1)
        setApplications([...applications])
      }
    }
    setAdjusterToInvite(undefined)
  }, [adjusterToInvite?._id, deleteApplicationMutation, applications])

  const inviteAdjuter = useCallback(
    async (message: string) => {
      setShowFilterLoader(true)
      const invitation = {
        message,
        adjusterId: adjusterToInvite?._id,
        jobId: job?._id,
        status: JobApplicationStatus.WAITING,
      }
      const result = await createInvitationMutation({
        variables: { invitation },
      })
      if (result?.data?.InvitationCreateOne?.record) {
        setInvitations((prev) => [
          ...prev,
          { ...result?.data?.InvitationCreateOne?.record },
        ])
        createNotificationMutation({
          variables: {
            notification: {
              userId: adjusterToInvite?._id,
              message: `You have received an invitation for the job "${job?.title}".`,
              //   link: `job/${selectedJob?._id}`,
              link: `${ADJUSTER_ROUTES.INVITATIONS}`,
              read: false,
            },
          },
        })
        deleteInvitedAdjusterApplication()
        showSuccessToast('Adjuster Invited Succesfully!')
      }
      setShowFilterLoader(false)
    },
    [
      adjusterToInvite?._id,
      createInvitationMutation,
      createNotificationMutation,
      deleteInvitedAdjusterApplication,
      job?._id,
      job?.title,
    ]
  )

  const onAdjustersSearch = useCallback(
    async ({
      searcherValue,
      location,
      jobTypes,
      yearsOfLicensedExperience,
      statuses,
      licenses,
      certifications,
    }: OnSearchFunctionParams) => {
      setAdjusterToInviteRequested(true)
      setShowFilterLoader(true)

      const locationAbbreviation = US_STATES.find(
        (a) => a.name.toLowerCase() === location.toLowerCase() ?? ''
      )?.abbreviation
      const licensesIds = licenses.filter((l) => l.id)?.map((l) => l.id)
      const licensesStates = licenses.filter((l) => !l.id)?.map((l) => l.state)
      const result = await getUsersByFilter({
        variables: {
          userType: UserType.ADJUSTER,
          searcherValue,
          locationAbbreviation,
          location,
          jobTypes,
          status: statuses,
          yearsOfLicensedExperience,
          licensesIds,
          licensesStates,
          certificationsIds: certifications.map((l) => l.id),
        },
        fetchPolicy: 'network-only',
      })

      if (result?.data?.UsersByFilter)
        setAdjustersToInvite([...result?.data?.UsersByFilter])

      setShowFilterLoader(false)
    },
    [getUsersByFilter]
  )

  const onDeleteJob = useCallback(async () => {
    if (setShowParentLoader) setShowParentLoader(true)
    const result = await updateJobMutation({
      variables: {
        job: { status: JobStatus.DELETED },
        id: job?._id,
      },
    })
    if (result?.data?.JobUpdateById) {
      deleteApplicationsMutation({
        variables: {
          id: job?._id,
        },
      })

      showSuccessToast('Job Deleted Succesfully!')
      if (onDelete) onDelete(job?._id)
      setJob(undefined)

      if (setShowParentLoader) setShowParentLoader(false)
    }
  }, [
    setShowParentLoader,
    updateJobMutation,
    job?._id,
    deleteApplicationsMutation,
    onDelete,
    setJob,
  ])

  const sendAdjustersNotifications = useCallback(async () => {
    const result = await rosters.map(async (roster) => {
      await createNotificationMutation({
        variables: {
          notification: {
            userId: roster?.adjusterId,
            message: `The job "${job?.title}" has been closed.`,
            //   link: `job/${selectedJob?._id}`,
            link: `${GLOBAL_ROUTES.JOBS}/${job?._id}`,
            read: false,
          },
        },
      })
    })

    Promise.all(result)
  }, [createNotificationMutation, job?._id, job?.title, rosters])

  const onFinishJob = useCallback(async () => {
    if (setShowParentLoader) setShowParentLoader(true)
    const finishDate = new Date()
    const result = await updateJobMutation({
      variables: {
        job: { status: JobStatus.FINISHED, finishDate },
        id: job?._id,
      },
    })
    if (result?.data?.JobUpdateById) {
      showSuccessToast('Job Updated Succesfully!')
      if (job) {
        const updatedJob = {
          ...job,
          status: JobStatus.FINISHED,
          finishDate: finishDate.toString(),
          typesDescription: job?.typesDescription ?? '',
        }
        setJob(updatedJob)
        if (onUpdate) onUpdate(updatedJob)
        sendAdjustersNotifications()
      }
    }
    if (setShowParentLoader) setShowParentLoader(false)
  }, [
    setShowParentLoader,
    updateJobMutation,
    job,
    setJob,
    onUpdate,
    sendAdjustersNotifications,
  ])

  const onUploadBillingStatement = useCallback(
    async (file: any) => {
      if (user) {
        const response = await storeFile(
          file,
          file.name,
          user,
          `jobs/${job._id}/billingStatements/`
        )
        const url = response?.data

        if (url) {
          const newBillingStatementsURLs = [...job.billingStatementsURLs, url]
          const result = await updateJobMutation({
            variables: {
              job: {
                billingStatementsURLs: newBillingStatementsURLs,
                typesDescription: job?.typesDescription || 'description',
              },
              id: job?._id,
            },
          })
          if (result?.data?.JobUpdateById) {
            const updatedJob = {
              ...job,
              billingStatementsURLs: newBillingStatementsURLs,
            }
            setJob(updatedJob)
            if (onUpdate) onUpdate(updatedJob)
            notifyAdmins(
              `A new billing statement for the job "${job?.title}" has been uploaded`,
              job?._id ?? ''
            )
            //   sendAdjustersNotifications()
          }
        }
      }
    },
    [job, notifyAdmins, onUpdate, setJob, updateJobMutation, user]
  )

  const getAdjusterCardCustomContent = useCallback(
    (adjuster: User) => {
      const invited = !!invitations.find((i) => i.adjusterId === adjuster._id)
      const hired = !!rosters.find((r) => r.adjusterId === adjuster._id)
      if (hired)
        return (
          <>
            <Tag color={ADJUSTANT_GREEN} backgroundColor="gray.100" size="sm">
              {adjuster?.status}
            </Tag>
            <Text fontSize="sm" color="gray.500">
              Hired
            </Text>
          </>
        )
      if (invited)
        return (
          <>
            <Tag color={ADJUSTANT_GREEN} backgroundColor="gray.100" size="sm">
              {adjuster?.status}
            </Tag>
            <Text fontSize="sm" color="gray.500">
              Invited
            </Text>
          </>
        )

      return (
        <Button
          //   color={ADJUSTANT_GREEN}
          //   cursor="pointer"
          //   fontSize="large"
          size="sm"
          variant="adjustant"
          onClick={() => {
            setAdjusterToInvite(adjuster)
            OnInviteModalOpen()
          }}
        >
          Invite Adjuster
        </Button>
      )
    },
    [OnInviteModalOpen, invitations, rosters]
  )

  return (
    <>
      <InviteAdjusterModal
        isOpen={isInviteModalOpen}
        onClose={(jobID, message, invite) => {
          onInviteModalClose()
          if (invite) {
            inviteAdjuter(message)
          }
        }}
        adjuster={adjusterToInvite}
        // jobsMapped={jobsMapped}
      />
      {job && !showLoader && (
        <Tabs
          variant="adjustant"
          h="100%"
          width="100%"
          index={selectedTab}
          onChange={(index) => {
            setSelectedTab(index)
          }}
        >
          <TabList
            background="white"
            width="100%"
            borderBottom="1px solid #e3e2e2"
          >
            <Tab
              fontWeight={500}
              width="50%"
              py={2}
              mx={0}
              fontSize={['sm', 'md']}
            >
              View Job Post
            </Tab>
            <Tab
              fontWeight={500}
              width="50%"
              py={2}
              mx={0}
              fontSize={['sm', 'md']}
            >
              Roster ({rostersUsers?.length})
            </Tab>

            {job?.status !== JobStatus.FINISHED && (
              <>
                <Tab
                  fontWeight={500}
                  width="50%"
                  py={2}
                  mx={0}
                  fontSize={['sm', 'md']}
                >
                  Review Applications ({applications.length})
                </Tab>
                <Tab
                  fontWeight={500}
                  width="50%"
                  py={2}
                  mx={0}
                  fontSize={['sm', 'md']}
                >
                  Invite Adjusters
                </Tab>
              </>
            )}
          </TabList>

          <TabPanels>
            {/* View Job Post */}
            <TabPanel>
              <Box p={[4, 8]}>
                {backButton}
                <JobDetails
                  specs={specs}
                  job={job}
                  firm={firm}
                  headerElement={<></>}
                  canUpdate={canUpdate}
                  canSetAsFinished={canSetAsFinished}
                  canDelete={canDelete}
                  onDeleteJob={onDeleteJob}
                  onFinishJob={onFinishJob}
                  onUploadBillingStatement={onUploadBillingStatement}
                />
              </Box>
            </TabPanel>
            {/* Roster */}
            <TabPanel>
              <Box p={[4, 8]}>
                {rostersUsers.length > 0 && (
                  <RosterComponent
                    selectedJobId={job?._id}
                    adjusters={rostersUsers}
                    rosters={rosters}
                    chats={chats}
                    setChats={setChats}
                  />
                )}
                {rostersUsers.length === 0 && (
                  <HStack
                    direction="row"
                    h="100%"
                    justifyContent="center"
                    mt="30%"
                  >
                    <Heading>No Adjusters Hired</Heading>
                  </HStack>
                )}
              </Box>
            </TabPanel>
            {/* Review Applications */}
            <TabPanel>
              <Box p={[2, 4, 8]}>
                {applicationsUsers.length > 0 && <Divider />}
                {applicationsUsers.map((adjuster) => (
                  <Box key={adjuster?._id}>
                    <AdjusterCard
                      adjuster={adjuster}
                      application={applications.find(
                        (a) => a.adjusterId === adjuster._id
                      )}
                      customHeaderContent={
                        <Text
                          as="b"
                          fontSize="sm"
                          color={
                            adjuster.meetRequirements ? ADJUSTANT_GREEN : 'red'
                          }
                        >
                          {adjuster.requirementsMessage}
                        </Text>
                      }
                      customFooterContent={
                        <HStack mr={2}>
                          <Tag
                            color={ADJUSTANT_GREEN}
                            backgroundColor="gray.100"
                            size="sm"
                          >
                            {adjuster?.status}
                          </Tag>
                          <Button
                            variant="reject"
                            size="sm"
                            onClick={() =>
                              acceptOrRejectApplication(false, adjuster)
                            }
                          >
                            Reject
                          </Button>
                          <Button
                            variant="adjustant"
                            size="sm"
                            isDisabled={rosters.length === job?.adjustersNeeded}
                            onClick={() =>
                              acceptOrRejectApplication(true, adjuster)
                            }
                          >
                            Accept
                          </Button>
                        </HStack>
                      }
                      replaceAdjusterName={user?.userType === UserType.FIRM}
                    />
                    <Divider />
                  </Box>
                ))}
                {applicationsUsers.length === 0 && (
                  <HStack
                    direction="row"
                    h="100%"
                    justifyContent="center"
                    mt="30%"
                  >
                    <Heading>No Applications Received</Heading>
                  </HStack>
                )}
              </Box>
            </TabPanel>
            {/* Invite Adjusters */}
            <TabPanel>
              <AdjusterSearcher
                adjusters={adjustersToInvite}
                showLoader={showFilterLoader}
                dataRequested={adjusterToInviteRequested}
                onSearch={onAdjustersSearch}
                getAdjusterCardCustomContent={getAdjusterCardCustomContent}
                replaceAdjusterName={user?.userType === UserType.FIRM}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      )}

      {showLoader && (
        <HStack direction="row" h="100%" justifyContent="center">
          <Spinner size="xl" />
        </HStack>
      )}
    </>
  )
}

export default FirmJobTabs
