import { HStack, Spinner, Box, Button, Stack } from '@chakra-ui/react'
import UserSearcherBox from '../../../components/UserSearcherBox/UserSearcherBox'
import { formatDate } from '../../../utils/functions'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useContext, useState, useMemo, useCallback, useEffect } from 'react'
import { StateLicesesContext } from '../../../contexts/StateLicesesContext'
import { TrainingCertificationsContext } from '../../../contexts/TrainingCertificationsContext'
import {
  GET_ADJUSTERS_BY_FILTERS,
  GET_ALL_JOBS_WITHOUT_FILTERS,
  GET_USER_BY_EMAIL,
} from '../../../graphql/Queries'
import {
  StateLicenseContextType,
  US_STATES,
} from '../../../types/stateLicensesTypes'
import { TrainingCertificationContextType } from '../../../types/trainingCertificationsTypes'
import {
  LicensingRequirement,
  CertificationRequirement,
  USER_STATUS,
  AdminAdjuster,
  DriverLicense,
  User,
  FemaCertification,
} from '../../../types/userTypes'
import {
  DamageType,
  JobLicensingRequirement,
  JobCertificationRequirement,
  Job,
} from '../../../types/jobTypes'
import { GLOBAL_ROUTES, ADMIN_ROUTES } from '../../../App'
import { ADJUSTANT_GREEN } from '../../../themes/themes'
import { useNavigate } from 'react-router-dom'
import CustomTable from '../../../components/CustomTable/CustomTable'
import { createColumnHelper } from '@tanstack/react-table'
import { CiEdit } from 'react-icons/ci'
import { SelectableItem } from '../../../components/CustomDropdown/CustomDropdown'
import { UPDATE_USER_BY_ID } from '../../../graphql/Mutations'

const AdminAdjusters = () => {
  const navigate = useNavigate()
  const { stateLicenses }: StateLicenseContextType =
    useContext(StateLicesesContext)
  const { trainingCertifications }: TrainingCertificationContextType =
    useContext(TrainingCertificationsContext)

  const [updateUser] = useMutation(UPDATE_USER_BY_ID)
  const [getUser] = useLazyQuery(GET_USER_BY_EMAIL)

  const [showLoader, setShowLoader] = useState<boolean>(false)
  const columnHelper = createColumnHelper<AdminAdjuster>()

  const columns = [
    columnHelper.accessor('lastName', {
      cell: (info) => info.getValue(),
      header: 'Last Name',
    }),
    columnHelper.accessor('firstName', {
      cell: (info) => info.getValue(),
      header: 'First Name',
    }),
    columnHelper.accessor('email', {
      cell: (info) => info.getValue(),
      header: 'Email',
    }),
    columnHelper.accessor('status', {
      cell: (info) => info.getValue().toString(),
      header: 'Deployment Status',
    }),
    columnHelper.accessor('documentsStatus', {
      cell: (info) => info.getValue()?.toString(),
      header: 'Documents Status',
    }),
    columnHelper.accessor('lastActive', {
      cell: (info) => (info.getValue() ? formatDate(info.getValue()) : '-'),
      header: 'Last Active',
    }),
    columnHelper.accessor('_id', {
      cell: (info) => {
        return (
          <CiEdit
            fontSize={20}
            cursor="pointer"
            color={ADJUSTANT_GREEN}
            onClick={() =>
              navigate(
                `${GLOBAL_ROUTES.ADMIN}${
                  GLOBAL_ROUTES.PROFILE
                }/${info.getValue()}`
              )
            }
          />
        )
      },
      header: '',
    }),
  ]

  const stateLicensesMapped = useMemo((): LicensingRequirement[] => {
    return (
      stateLicenses?.map((s) => ({
        id: s._id,
        name: s.name,
        isListed: true,
      })) ?? []
    )
  }, [stateLicenses])

  const trainingCertificationsMapped =
    useMemo((): CertificationRequirement[] => {
      return (
        trainingCertifications?.map((s) => ({
          id: s._id,
          name: s.name,
          isListed: true,
        })) ?? []
      )
    }, [trainingCertifications])

  //Filters
  const [searcherValue, setSearcherValue] = useState<string>('')
  const [location, setLocation] = useState<string>('')
  const [jobTypes, setJobTypes] = useState<DamageType[]>([])
  const [statuses, setStatuses] = useState<USER_STATUS[]>([])
  const [documentsStatus, setDocumentsStatus] = useState<USER_STATUS | string>(
    'all'
  )
  const [yearsOfLicensedExperience, setYearsOfLicensedExperience] =
    useState<number>(0)
  const [licenses, setLicenses] = useState<JobLicensingRequirement[]>([])
  const [certifications, setCertifications] = useState<
    JobCertificationRequirement[]
  >([])
  const [adjusters, setAdjusters] = useState<AdminAdjuster[]>([])

  //Admin filters
  const [jobsListRetrieved, setJobsListRetrieved] = useState(false)
  const [jobsList, setJobsList] = useState<SelectableItem[]>([])
  const [jobId, setJobId] = useState<string>('')
  const [notListedCertificationName, setNotListedCertificationName] =
    useState<string>('')

  const [getAllJobs] = useLazyQuery(GET_ALL_JOBS_WITHOUT_FILTERS, {
    onCompleted: (data: { JobMany: Job[] }) => {
      setJobsListRetrieved(true)
      setShowLoader(false)
      if (data?.JobMany) {
        const jobsMapped = data?.JobMany.map((job) => ({
          value: job._id,
          label: `${job.title} - ${job.status}`,
        }))
        setJobsList(jobsMapped)
      }
    },
    onError: (err) => {
      setJobsListRetrieved(true)
      setShowLoader(false)
      console.log(err)
    },
  })

  useEffect(() => {
    if (!jobsListRetrieved) {
      setShowLoader(false)
      getAllJobs()
    }
  }, [getAllJobs, jobsListRetrieved])

  const [getAdjustersByFilter] = useLazyQuery(GET_ADJUSTERS_BY_FILTERS, {
    onCompleted: (data: { AdjustersByFilter: AdminAdjuster[] }) => {
      setShowLoader(false)
      if (data?.AdjustersByFilter) {
        const newUsers = [...data?.AdjustersByFilter]
        setAdjusters(newUsers)
      }
    },
    onError: (err) => {
      setShowLoader(false)
      console.log(err)
    },
  })

  const onSearch = useCallback(() => {
    setShowLoader(true)
    const locationAbbreviation = US_STATES.find(
      (a) => a.name.toLowerCase() === location.toLowerCase() ?? ''
    )?.abbreviation
    getAdjustersByFilter({
      variables: {
        searcherValue,
        location,
        locationAbbreviation,
        jobTypes,
        status: statuses,
        ...(documentsStatus !== 'all' ? { documentsStatus } : {}),
        licenses: licenses.map((l) => l.id),
        certifications: certifications.map((l) => l.id),
        notListedCertificationName,
        jobId,
        yearsOfLicensedExperience,
      },
      fetchPolicy: 'network-only',
    })
  }, [
    getAdjustersByFilter,
    searcherValue,
    location,
    jobTypes,
    statuses,
    documentsStatus,
    licenses,
    certifications,
    notListedCertificationName,
    jobId,
    yearsOfLicensedExperience,
  ])

  // BE CAREFUL!!!!! THIS UPDATES EVERY ADJUSTER
  const updateAdjusters = useCallback(async () => {
    setShowLoader(true)

    const result = await adjusters.map(async (adjuster) => {
      try {
        const adjusterc = (
          await getUser({ variables: { email: adjuster.email } })
        )?.data?.UserOne
        // const f = (adjusterc as any)?.femaCertification
        // const { category, ...data } = d
        const d = (adjusterc as any)?.driverLicense
        //   console.log(data)
        if (Object.keys(d).includes('has')) {
          // const driverLicense: FemaCertification = {
          //   ...data,
          //   categories: [category],
          // }
          await updateUser({
            variables: {
              user: { driverLicense: undefined },
              id: adjusterc?._id,
            },
          })
          console.log(adjusterc?._id, adjusterc?.email)
        }
      } catch (error) {
        console.log('ERROR FOR', adjuster.email)
      }
    })
    await Promise.all(result)

    setShowLoader(false)
  }, [adjusters, getUser, updateUser])

  return (
    <Box
      h="100%"
      width="100%"
      backgroundColor="white"
      pt={8}
      px={[4, 8]}
      overflowY="auto"
    >
      <HStack alignItems="start">
        <Box width="-webkit-fill-available">
          <UserSearcherBox
            placeholder="Search Adjusters"
            showFilters
            // showAdjustersFilters
            value={searcherValue}
            setValue={setSearcherValue}
            location={location}
            setLocation={setLocation}
            jobTypes={jobTypes}
            setJobTypes={setJobTypes}
            statuses={statuses}
            setStatuses={setStatuses}
            certifications={certifications}
            licenses={licenses}
            setCertifications={setCertifications}
            setLicenses={setLicenses}
            licensesOptions={stateLicensesMapped}
            certificationOptions={trainingCertificationsMapped}
            onSearch={onSearch}
            showAdminAdjustersFilters
            documentsStatus={documentsStatus}
            setDocumentsStatus={setDocumentsStatus}
            jobsList={jobsList}
            jobId={jobId}
            setJobId={setJobId}
            notListedCertificationName={notListedCertificationName}
            setNotListedCertificationName={setNotListedCertificationName}
            yearsOfLicensedExperience={yearsOfLicensedExperience}
            setYearsOfLicensedExperience={setYearsOfLicensedExperience}
          />
        </Box>
        <Button
          variant="adjustant"
          _hover={{ backgroundColor: ADJUSTANT_GREEN }}
          onClick={() =>
            navigate(`${GLOBAL_ROUTES.ADMIN}${ADMIN_ROUTES.INVITE_ADJUSTER}`)
          }
        >
          Onboard Adjuster
        </Button>
        {/* BE CAREFUL WITH THIS BUTTON */}
        {/* <Button
          //   variant="adjustant"
          background="red"
          color="white"
          _hover={{ backgroundColor: 'red' }}
          onClick={updateAdjusters}
        >
          Update Adjusters
        </Button> */}
      </HStack>

      <Box overflowY="auto" width="100%" height="calc(100% - 42px)">
        {showLoader && (
          <HStack
            direction="row"
            height="inherit"
            w="100%"
            justifyContent="center"
          >
            <Spinner size="xl" />
          </HStack>
        )}

        {adjusters.length > 0 && (
          <CustomTable data={adjusters} columns={columns} />
        )}
      </Box>
    </Box>
  )
}

export default AdminAdjusters
